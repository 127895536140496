<template>
  <div class="app-header">
    <div class="tf-container">
      <div class="tf-topbar d-flex justify-content-between align-items-center">
        <a class="user-info d-flex justify-content-between align-items-center" href="javascript:;" @click="acessarPerfil">
          <img :src="foto ? foto : 'images/user/user-icon.png'" alt="image" />
          <div class="content">
            <h4 class="white_color">
              {{ retornaNome() }}
            </h4>
            <p class="white_color fw_4">
              Fique em dia e livre de preocupações!
            </p>
          </div>
        </a>
        <a v-if="(associacoes) && (associacoes.length)" class="user-info d-flex justify-content-between align-items-center" href="javascript:;">
          <img 
            v-if="associacoes[0].ID_EMITENTE_ML == '428'" 
            src="images/logo-sbo-anuidade-white.png" 
            alt="image" 
            style="border-radius: 0; width: 30px; height: 30px;" 
          />
          <img 
            v-if="associacoes[0].ID_EMITENTE_ML == '1024839'" 
            src="images/logo-abp-anuidade-white.png" 
            alt="image" 
            style="border-radius: 0; width: 30px; height: 30px;" 
          />
          <div class="content">
            <h4 class="white_color" style="font-size: 10px;line-height: 16px;">
              {{ retornaTitulo() }}
            </h4>
            <p class="white_color fw_4" style="line-height: 12px; font-size: 8px;">
              {{ retornaAssociacao() }}
            </p>
          </div>
        </a>
        <a href="#" id="btn-popup-up" class="icon-notification1">
          <span>2</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TopoAplicacao",
  data() {
    return {};
  },
  async mounted() {
    await this.$nextTick();
  },
  computed: {
    ...mapGetters(["nome", "foto", "associacoes", "nomeApresentacao"])
  },
  methods: {
    retornaNome() {
      if(this.nomeApresentacao) {
        return this.nomeApresentacao;
      } else {
        let nome = this.nome.split(" ");
        if(nome.length > 1) {
          return nome[0] + " " + nome[nome.length - 1];
        } else {
          return nome[0];
        }
      }
    },
    retornaTitulo() {
      let tituloAux = "";

      if((this.associacoes) && (this.associacoes.length)) {
        tituloAux = this.associacoes[0].CATEGORIA;
      }

      return tituloAux;
    },
    retornaAssociacao() {
      let associacaoAux = "";

      if((this.associacoes) && (this.associacoes.length)) {
        associacaoAux = this.associacoes[0].DESCRICAO;
      }

      return associacaoAux;
    },
    acessarPerfil(){
      this.$router.push({
        name: "perfil"
      });
    }
  }
}
</script>

<style>

</style>