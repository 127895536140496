<template>
  <div class="group-input">
    <label>
      {{ label ? label: 'CEP' }} <span v-if="required" style="color: red">*</span>
    </label>
    <input 
      type="text" 
      v-model="valor" 
      :placeholder="placeholder" 
      v-maska 
      data-maska="#####-###" 
      :disabled="disabled" 
      @blur="busca"
    >
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "InputCEP", 
  props: [
    "modelValue", 
    "label", 
    "placeholder", 
    "value", 
    "disabled", 
    "required"
  ],
  computed: {
    valor: {
      get: function() {
        return (this.modelValue ? this.modelValue : this.value);
      },
      set: function(newValue) {
        this.$emit("update:modelValue", newValue);
      }
    }
  },
  methods: {
    async busca() {
      let cep = this.valor;
      if ((cep) && (cep.length) && (cep.length === 9)) {
        cep = cep.replace("-", "");
        const url = `https://viacep.com.br/ws/${cep}/json/`;
        const response = await axios.get(url);
        const { data } = response;
        this.$emit("busca", data);
      }
    }
  }
}
</script>

<style scoped>

</style>