import { createStore } from "vuex";

import auth from "./auth.module";
import emitentes from "./emitentes.module";
import fluxos from "./fluxos.module";
import projetos from "./projetos.module";

export default createStore({
  modules: {
    auth, 
    emitentes, 
    fluxos, 
    projetos
  }
})
