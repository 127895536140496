<template>
  <div class="group-input">
    <label>
      {{ label }} <span v-if="required" style="color: red">*</span>
    </label>
    <input 
      :type="type ? type : 'date'" 
      v-model="valor" 
      :placeholder="placeholder" 
      :disabled="disabled"
      :style="{
        'background': (disabled ? 'rgb(0 0 0 / 7%)' : '')
      }"
    />
  </div>
</template>

<script>
export default {
  name: "InputDate",
  props: [
    "modelValue", 
    "label", 
    "placeholder", 
    "value", 
    "disabled", 
    "type", 
    "required"
  ],
  computed: {
    valor: {
      get: function() {
        return (this.modelValue ? this.modelValue : this.value);
      },
      set: function(newValue) {
        this.$emit("update:modelValue", newValue);
      }
    }
  }
}
</script>

<style>

</style>