<template>
  <div class="preload preload-container">
    <div class="preload-logo"></div>
  </div>
</template>

<script>
export default {
  name: "AppLoader"
}
</script>

<style scoped>

</style>