import moment from "moment";

const ID_TOKEN_KEY = "token_paylogos_app";

export const getToken = () => {
    return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
    window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const criarObterGenericoComValidade = (configuracao, valor) => {
    let valorSalvo = window.localStorage.getItem(configuracao);

    if (valorSalvo) {
        valorSalvo = JSON.parse(valorSalvo);

        if ((valorSalvo) && (valorSalvo.validade == moment().format("YYYY-MM-DD"))) {
            return valorSalvo.valor;
        } else {
            window.localStorage.setItem(configuracao, JSON.stringify({
                valor, 
                validade: moment().format("YYYY-MM-DD")
            }));
            return valor;
        }
    } else {
        window.localStorage.setItem(configuracao, JSON.stringify({
            valor, 
            validade: moment().format("YYYY-MM-DD")
        }));
        return valor;
    }
};

export const salvarGenericoComValidade = (configuracao, valor) => {
    window.localStorage.setItem(configuracao, JSON.stringify({
        valor,
        validade: moment().format("YYYY-MM-DD")
    }));
};

export default {
    getToken, 
    saveToken, 
    destroyToken, 
    criarObterGenericoComValidade, 
    salvarGenericoComValidade
};