<template>
  <div>
    <div class="d-flex align-items-center mb-1">
      <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" :class="{'active': senhaQualidade >= 1}"></div>
      <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" :class="{'active': senhaQualidade >= 2}"></div>
      <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" :class="{'active': senhaQualidade >= 3}"></div>
      <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px" :class="{'active': senhaQualidade >= 4}"></div>
    </div>
    <div class="text-muted">Use 8 ou mais caracteres contendo letras maiúsculas e minúsculas, números e símbolos.</div>
  </div>
</template>

<script>
export default {
  name: "QualidadeSenha",
  data() {
    return {
      senhaQualidade: 0
    };
  },
  props: ["senha"],
  watch: {
    senha() {
      this.$nextTick(() => {
        this.senhaQualidade = 0;

        if(this.senha.length >= 8) {
          this.senhaQualidade += 1;
        }

        if((this.senha.toUpperCase() != this.senha) && (this.senha.toLowerCase() != this.senha)) {
          this.senhaQualidade += 1;
        }

        if(/\d/.test(this.senha)) {
          this.senhaQualidade += 1;
        }

        if(/[ !@#$%^&*+?~]/.test(this.senha)) {
          this.senhaQualidade += 1;
        }
      });
    }
  },
  methods: {
    obterQualidade() {
      return this.senhaQualidade;
    }
  }
}
</script>

<style scoped>
  .h-5px {
    height: 5px;
  }

  .bg-active-success.active {
    background-color: #418d4d !important;
  }
</style>