<template>
  <img src="/images/loading.svg" :style="{'width': size + 'px'}" />
</template>

<script>
export default {
  name: "LoadingIcon",
  props: ["size"]
}
</script>

<style>

</style>