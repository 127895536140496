import ApiService from "@/store/api.service";

const state = {
  projetoAcquisicao: {},
  projetoAcquisicaoDireta: {}
};

const getters = {
  projetoAcquisicao(state) {
    return state.projetoAcquisicao;
  },
  projetoAcquisicaoDireta(state) {
    return state.projetoAcquisicaoDireta;
  }
};

const mutations = {
  setProjetoAcquisicao(state, projetoAcquisicao) {
    state.projetoAcquisicao = projetoAcquisicao;
  },
  setProjetoAcquisicaoDireta(state, projetoAcquisicaoDireta) {
    state.projetoAcquisicaoDireta = projetoAcquisicaoDireta;
  }
};

const actions = {
  updateProjetoAcquisicao(context, projetoAcquisicao) {
    context.commit("setProjetoAcquisicao", projetoAcquisicao);
  },
  updateProjetoAcquisicaoDireta(context, projetoAcquisicaoDireta) {
    context.commit("setProjetoAcquisicaoDireta", projetoAcquisicaoDireta);
  },
  listarProdutosLoja() {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.get("paylogosAppProjeto/access/listarProdutosLoja").then(({ data }) => {
        resolve(data);
      });
    });
  },
  obterProjetoWebHash(context, hash) {
    return new Promise(resolve => {
      ApiService.get("paylogosAppAccess/acessarProjeto/" + hash).then(({ data }) => {
        resolve(data);
      });
    });
  }
};

export default {
  state, 
  actions, 
  mutations, 
  getters
};