<template>
  <div class="group-input">
    <label>
      {{ label ? label: 'Celular' }} <span v-if="required" style="color: red">*</span>
    </label>
    <input 
      type="text" 
      v-model="valor" 
      :placeholder="placeholder" 
      v-maska:[optionsMask] 
      :disabled="disabled"
      :style="{
        'background': (disabled ? 'rgb(0 0 0 / 7%)' : '')
      }"
    >
  </div>
</template>

<script>
export default {
  name: "InputCelular", 
  data() {
    return {
      optionsMask: {
        mask: ["(##) ####-####", "(##) #####-####"]
      }
    };
  },
  props: [
    "modelValue", 
    "label", 
    "placeholder", 
    "value", 
    "disabled", 
    "required"
  ],
  computed: {
    valor: {
      get: function() {
        return (this.modelValue ? this.modelValue : this.value);
      },
      set: function(newValue) {
        this.$emit("update:modelValue", newValue);
      }
    }
  }
}
</script>

<style scoped>

</style>