<template>
  <HeaderComponent 
    titulo="Loja" 
    @voltar="voltar"
  />
  <div id="app-wrap">
    <div class="tf-container">
      <div class="input-field mt-3">
        <span 
          class="icon-search"
        ></span>
        <input 
          type="text" 
          v-model="busca" 
          class="search-field value_input" 
          placeholder="Buscar"
        />
        <span 
          v-if="busca" 
          class="icon-clear" 
          @click="busca = ''" 
          style="cursor: pointer;"
        ></span>
      </div>
      <div class="mt-5">
        <div class="d-flex justify-content-between">
          <h3>
            {{ isLoading ? "Carregando seus produtos..." : (produtos.length ? "Seus produtos disponíveis" : "Você ainda não tem produtos disponíveis") }}
          </h3>
        </div>
        <ul class="box-service st1" v-if="isLoading">
          <li>
            <LoadingIcon size="100" />
          </li>
        </ul>
        <ul class="box-service st1" v-if="(produtos.length) && (!isLoading)">
          <li :class="{'item-pago': produto.PAGO}" v-for="produto in filtrarProdutos(produtos)" :key="produto.ID_PROJETO" @click="acessarProduto(produto)" style="cursor: pointer;">
            <div 
              class="food-box" 
              :class="{
                'food-box-pago': produto.PAGO, 
                'skeleton-effect-wave': produto.PAGO
              }"
            >
              <div class="img-box hide-discont">
                <img 
                  v-if="(!produto.PROJETO_WEB) || (!produto.PROJETO_WEB.IMAGEM_THUMB)" 
                  src="images/rewards/gift-3.jpg" 
                  alt="images"
                />
                <img 
                  v-if="(produto.PROJETO_WEB) && (produto.PROJETO_WEB.IMAGEM_THUMB)" 
                  :src="'https://backend.micrologos.com.br/paylogosAppAccess/acessarProjetoImagemThumb/' + produto.PROJETO_WEB.HASH" 
                  alt="images" 
                  style="height: initial;"
                />
                <!--<span>Disponível</span>-->
              </div>
              <div class="content">
                <div v-if="produto.PAGO" style="padding: 2px 8px; background-color: #d9a300; color: #ffffff; font-size: 10px; line-height: 16px; border-radius: 4px; text-align: center; margin-bottom: 3px;">
                  Adquirido - {{ formataData(produto.DATA_PAGAMENTO) }}
                </div>
                <h4>
                  <a href="javascript:;" :class="{'color-pago': produto.PAGO}">
                    {{ produto.DESCRICAO }}
                  </a>
                </h4>
                <h6 style="margin-top: 3px;">
                  <a href="javascript:;" :class="{'color-pago': produto.PAGO}">
                    {{ produto.EMITENTE_ML }}
                  </a>
                </h6>
                <div class="rating mt-2" style="display: none;">
                  <ul class="list-star">
                    <li class="icon-star"></li>
                    <li class="icon-star"></li>
                    <li class="icon-star"></li>
                    <li class="icon-star"></li>
                    <li class="icon-star"></li>
                  </ul>
                  <span>4.1 <i class="dot"></i> 100+ rating</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <MenuInferior />
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";
import { mapActions } from "vuex";

import HeaderComponent from "@/components/template/HeaderComponent.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import MenuInferior from "@/components/template/MenuInferior.vue";

export default {
  name: "LojaProdutos",
  data() {
    return {
      isLoading: false, 
      busca: "", 
      produtos: []
    };
  },
  components: {
    HeaderComponent, 
    LoadingIcon, 
    MenuInferior
  },
  async mounted() {
    this.isLoading = true;
    this.produtos = await this.listarProdutosLoja();
    this.isLoading = false;
  },
  beforeUnmount() {
    
  },
  methods: {
    ...mapActions(["listarProdutosLoja", "updateProjetoAcquisicao"]), 
    voltar() {
      this.$router.push({
        name: "home"
      });
    }, 
    formataData(data) {
      return moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    filtrarProdutos(produtos) {
      if(this.busca) {
        return produtos.filter(produto => {
          return produto.DESCRICAO.toLowerCase().includes(this.busca.toLowerCase());
        });
      } else {
        return produtos;
      }
    },
    acessarProduto(produto) {
      if(produto.PAGO) {
        Swal.fire({
          icon: "success", 
          title: "Sucesso!", 
          text: "Você já adquiriu esse produto, obrigado!", 
          showConfirmButton: false, 
          timer: 3000
        });

        this.$confetti.start();

        setTimeout(() => {
          this.$confetti.stop();
        }, 2000);
      } else {
        this.updateProjetoAcquisicao(produto);

        this.$router.push({
          name: "produtoReview"
        });
      }
    }
  }
}
</script>

<style scoped>
  .food-box-pago {
    border: 3px solid #ffc107;
    border-radius: 10px;
    text-shadow: 0px 0px 3px #ffc107, 0px 0px 4px #fff;
  }

  .color-pago {
    color: #d9a300;
  }

  .food-box .img-box.hide-discont {
    height: 210.88px;
    line-height: 210.88px;
  }

  .food-box .img-box.hide-discont::before {
    display: none;
  }

  @keyframes skeleton-effect-wave {
    0% {
      -webkit-mask-position: 50% top;
      mask-position: 50% top;
    }
    90% {
      -webkit-mask-position: 50% top;
      mask-position: 50% top;
    }
    100% {
      -webkit-mask-position: -150% top;
      mask-position: -150% top;
    }
  }

  .skeleton-effect-wave {
    -webkit-mask-image: linear-gradient(to right, transparent 0%, black 25%, black 75%, transparent 100%);
    mask-image: linear-gradient(to right, transparent 0%, black 25%, black 75%, transparent 100%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    -webkit-mask-repeat: repeat;
    mask-repeat: repeat;
    -webkit-mask-position: 50% top;
    mask-position: 50% top;
    animation: skeleton-effect-wave 5s infinite;
  }

  .item-pago {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  @media (max-width: 1000px) {
    .box-service {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 700px) {
    .box-service {
      grid-template-columns: repeat(2, 1fr);
    }
  }
</style>