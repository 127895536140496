<template>
  <div :id="'captcha' + idLocal"></div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "InputRecaptcha",
  data() {
    return {
      idLocal: uuidv4()
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.verificaIniciaCaptcha();
    });
  },
  methods: {
    verificaIniciaCaptcha() {
      if(!window.grecaptcha) {
        setTimeout(() => {
          this.verificaIniciaCaptcha();
        }, 1000);
      } else {
        window.grecaptcha.render("captcha" + this.idLocal, {
          sitekey: "6LcVmOUoAAAAAFbuKX9p-Kk_z-4YP-yWDfAeSm5a"
        });
      }
    },
    getResponse() {
      return window.grecaptcha.getResponse();
    }
  }
}
</script>

<style>

</style>