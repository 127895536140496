<template>
  <div 
    class="header-style2 produto-review-header" 
    :style="{
      'background': (((projetoWeb.ID_PROJETO_WEB) && (projetoWeb.IMAGEM)) ? 'url(' + 'https://backend.micrologos.com.br/paylogosAppAccess/acessarProjetoImagem/' + projetoWeb.HASH + ')' : 'url(../images/rewards/gift-3.jpg)')
    }"
    style="background-position: center center; background-repeat: no-repeat; background-size: contain !important;"
  >
    <div class="tf-container"></div>
  </div>
  <div class="mb-8" style="margin-bottom: 76px !important;">
    <div class="app-section bg_white_color giftcard-detail-section-1">
      <div class="tf-container">
        <div class="voucher-info">
          <h2 class="fw_6">{{ projetoWeb.DESCRICAO }}</h2>
          <a href="javascript:;" class="critical_color fw_6">
            {{ valorProduto ? formataValorMonetario(valorProduto) : "Selecione sua categoria" }}
          </a>
        </div>
        <div class="mt-2">
          <a href="#" class="note-voucher">
            Adquira agora mesmo
          </a>
          <p v-if="projetoWeb.DATA_FIM" class="mt-2 fw_4">
            Disponível até {{ formataData(projetoWeb.DATA_FIM) }}
          </p>
        </div>
      </div>
    </div>
    <div class="app-section mt-1 bg_white_color giftcard-detail-section-2">
      <div class="tf-container">
        <div class="voucher-desc" v-if="(projetoWeb.CATEGORIAS) && (projetoWeb.CATEGORIAS.length)">
          <div class="tf-form">
            <h4 class="fw_6" style="margin-bottom: 19px;">
              Suas Informações
            </h4>
            <InputSelect 
              label="Categoria" 
              v-model="emitente.idCategoriaEmitente" 
              :items="categorias" 
              @change="verificaValorCategoria"
            />
            <InputPais 
              label="País" 
              v-model="emitente.pais"
            />
            <InputText 
              label="Nome Completo" 
              v-model="emitente.nome" 
              :required="true"
            />
            <InputCPF 
              v-if="emitente.pais == 'BR'" 
              label="CPF" 
              v-model="emitente.cpf" 
              :required="true" 
              @cpfValido="cpfValido"
            />
            <InputText 
              v-if="emitente.pais != 'BR'" 
              label="Documento de Identificação" 
              v-model="emitente.documento" 
              :required="true"
            />
            <InputDate 
              label="Data Nascimento" 
              v-model="emitente.dataNascimento" 
              :required="true"
            />
            <InputSelect 
              label="Orgão de Classe" 
              v-model="emitente.orgaoClasse" 
              :items="orgaosClasses"
            />
            <InputText 
              label="Instituição, Organização em que trabalha" 
              v-model="emitente.instituicaoOrganizacaoTrabalha"
            />
            <InputText 
              label="Profissão / Especialidade" 
              v-model="emitente.profissaoEspecialidade"
            />
            <InputCEP 
              label="CEP" 
              v-model="emitente.cep" 
              :required="emitente.pais == 'BR' ? true : false" 
              @busca="buscaCEP"
            />
            <InputText 
              label="Logradouro" 
              v-model="emitente.logradouro" 
              :required="emitente.pais == 'BR' ? true : false"
            />
            <InputNumber 
              ref="logradouroNumero"
              label="Logradouro Número" 
              v-model="emitente.logradouroNumero" 
              :required="emitente.pais == 'BR' ? true : false"
            />
            <InputText 
              label="Logradouro Complemento" 
              v-model="emitente.logradouroComplemento" 
              :required="emitente.pais == 'BR' ? true : false"
            />
            <InputText 
              label="Bairro" 
              v-model="emitente.bairro" 
              :required="emitente.pais == 'BR' ? true : false"
            />
            <InputText 
              label="Cidade" 
              v-model="emitente.cidade" 
              :required="emitente.pais == 'BR' ? true : false"
            />
            <InputEstado 
              v-if="emitente.pais == 'BR'" 
              label="Estado" 
              v-model="emitente.estado" 
              :required="emitente.pais == 'BR' ? true : false"
            />
            <InputText 
              v-if="emitente.pais != 'BR'"
              label="Estado (Duas letras)" 
              v-model="emitente.estado" 
              :maxlength="2" 
              :required="emitente.pais == 'BR' ? true : false"
            />
            <InputCelular 
              label="Telefone" 
              v-model="emitente.telefone"
            />
            <InputCelular 
              label="Celular" 
              v-model="emitente.celular" 
              :required="true"
            />
            <InputText 
              label="E-mail" 
              v-model="emitente.email" 
              :required="emitente.pais == 'BR' ? true : false"
            />
            <InputSenha 
              v-if="cadastraSenha" 
              label="Cadastre uma senha para acessar o app e consultar seus pagamentos" 
              v-model="emitente.senha" 
              style="margin-top: 28px;" 
              :required="true" 
              :disabled="isLoading"
            />
            <QualidadeSenha 
              v-if="cadastraSenha" 
              ref="qualidadeSenha" 
              :senha="emitente.senha" 
              style="margin-top: 20px;"
            />
            <InputSenha 
              v-if="cadastraSenha" 
              label="Repita a Senha" 
              v-model="emitente.senhaRepeticao" 
              style="margin-top: 20px;" 
              :required="true" 
              :disabled="isLoading"
            />
            <h4 class="fw_6" style="margin-top: 20px; margin-bottom: 20px;">
              Informações para Recibo (caso o recibo seja para empresa, por favor preencha os campos abaixo)
            </h4>
            <InputText 
              label="Nome Recibo" 
              v-model="emitente.nomeRecibo"
            />
            <InputCNPJ 
              label="CNPJ" 
              v-model="emitente.cnpjRecibo"
            />
          </div>
        </div> 
        <div class="voucher-desc" v-if="(projetoWeb.ID_PROJETO_WEB) && (projetoWeb.DESCRICAO_DETALHADA)">
          <h4 class="fw_6">Informações do Produto</h4>
          <div 
            class="mt-1 info-mais-produto" 
            :class="{'info-mais-produto-ativo': exibirMaisInformacoes}" 
            v-html="projetoWeb.DESCRICAO_DETALHADA" 
          >
          </div>
          <a href="javascript:;" class="btn-view-more" @click="exibirMaisInformacoes = !exibirMaisInformacoes">
            {{ exibirMaisInformacoes ? 'Ver menos' : 'Ver mais' }}
            <i :class="{'icon-down': !exibirMaisInformacoes, 'icon-up': exibirMaisInformacoes}"></i>
          </a>
        </div> 
      </div>
    </div>
    <div class="app-section mt-1 bg_white_color giftcard-detail-section-4">
      <div class="tf-container">
        <div class="mt-3 d-flex justify-content-between top">
          <div class="logo icon-box">
            <img :src="'https://backend.micrologos.com.br/auth/logomarcaCliente/' + projetoWeb.ID_EMITENTE_ML" alt="image">
          </div>
          <div class="desc">
            <p class="fw_4">
              Oferecido por
            </p>
            <h4 class="fw_6">
              {{ projetoWeb.EMITENTE_ML }}
            </h4>
          </div>
          <a href="#" class="icon-right"></a>
        </div>
        <p class="text-center fw_4 mt-3 mb-2">
          Esse produto está disponível
        </p>
      </div>
    </div>
  </div>
  <div class="bottom-navigation-bar bottom-btn-fixed">
    <div class="tf-container">
      <ButtonPadrao 
        label="Comprar" 
        @click="comprar" 
        :isLoading="isLoading"
      />
    </div>
  </div>
  <AlertBox ref="alertBox" @dismiss="dismissAlert">
  </AlertBox>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import { mapActions } from "vuex";

import InputSelect from "@/components/inputs/InputSelect.vue";
import InputText from "@/components/inputs/InputText.vue";
import InputNumber from "@/components/inputs/InputNumber.vue";
import InputDate from "@/components/inputs/InputDate.vue";
import InputCPF from "@/components/inputs/InputCPF.vue";
import InputCNPJ from "@/components/inputs/InputCNPJ.vue";
import InputCEP from "@/components/inputs/InputCEP.vue";
import InputCelular from "@/components/inputs/InputCelular.vue";
import InputEstado from "@/components/inputs/InputEstado.vue";
import InputPais from "@/components/inputs/InputPais.vue";
import InputSenha from "@/components/inputs/InputSenha.vue";

import QualidadeSenha from "@/components/QualidadeSenha.vue";
import ButtonPadrao from "@/components/buttons/ButtonPadrao.vue";

import AlertBox from "@/components/AlertBox.vue";

export default {
  name: "ProdutoView", 
  components: {
    InputSelect, 
    InputText, 
    InputNumber, 
    InputDate, 
    InputCPF, 
    InputCNPJ, 
    InputCEP, 
    InputCelular, 
    InputEstado, 
    InputPais, 
    InputSenha, 
    QualidadeSenha, 
    ButtonPadrao, 
    AlertBox
  }, 
  data() {
    return {
      isLoading: false, 
      exibirMaisInformacoes: false, 
      cadastraSenha: false, 
      projetoWeb: {}, 
      categorias: [], 
      orgaosClasses: [{
        value: "CRM", 
        text: "CRM"
      }, {
        value: "CRP", 
        text: "CRP"
      }], 
      valorProduto: "", 
      emitente: {
        idCategoriaEmitente: "0",
        nome: "", 
        cpf: "", 
        documento: "", 
        dataNascimento: "", 
        orgaoClasse: "0", 
        instituicaoOrganizacaoTrabalha: "", 
        profissaoEspecialidade: "", 
        cep: "", 
        logradouro: "", 
        logradouroNumero: "", 
        logradouroComplemento: "", 
        bairro: "", 
        cidade: "", 
        estado: "", 
        pais: "BR", 
        telefone: "", 
        celular: "", 
        email: "", 
        senha: "", 
        senhaRepeticao: "", 
        nomeRecibo: "", 
        cnpjRecibo: ""
      }
    };
  },
  async mounted() {
    if(this.$route.params.hash) {
      this.projetoWeb = await this.obterProjetoWebHash(this.$route.params.hash);

      if(this.projetoWeb.CATEGORIAS) {
        this.categorias = this.projetoWeb.CATEGORIAS.map(categoria => {
          return {
            value: categoria.ID_EMITENTE_CATEGORIA, 
            text: categoria.DESCRICAO
          };
        });
      }
    }
  },
  methods: {
    ...mapActions([
      "obterProjetoWebHash", 
      "updateProjetoAcquisicaoDireta", 
      "incluirFluxoProjetoDireto", 
      "obterFluxoDireto", 
      "updatePendenciaPagar", 
      "getUserRegistered"
    ]), 
    formataData(data) {
      return moment(data, "YYYYMMDD[T]HHmmss").format("DD/MM/YYYY");
    },
    formataValorMonetario(valor) {
      if(valor) {
        return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
      } else {
        return "";
      }
    },
    async verificaValorCategoria() {
      await this.$nextTick();

      if(this.emitente.idCategoriaEmitente) {
        let categoria = this.projetoWeb.CATEGORIAS.find(categoria => categoria.ID_EMITENTE_CATEGORIA === this.emitente.idCategoriaEmitente);

        if((categoria) && (categoria.VALOR_PROJETO)) {
          this.valorProduto = categoria.VALOR_PROJETO;
        } else {
          this.valorProduto = "";
        }
      }
    },
    async buscaCEP(dados) {
      if(dados.bairro) {
        this.emitente.bairro = dados.bairro;
      }
      if(dados.complemento) {
        this.emitente.logradouroComplemento = dados.complemento;
      }
      if(dados.localidade) {
        this.emitente.cidade = dados.localidade;
      }
      if(dados.logradouro) {
        this.emitente.logradouro = dados.logradouro;
      }
      if(dados.uf) {
        this.emitente.estado = dados.uf;
      }
      this.emitente.logradouroNumero = "";

      await this.$nextTick();

      this.$refs.logradouroNumero.focus();
    },
    async comprar() {
      if(!this.emitente.idCategoriaEmitente) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "A categoria é obrigatório", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if(!this.emitente.nome) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "O nome completo é obrigatório", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if((this.emitente.pais == "BR") && (!this.emitente.cpf)) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "O CPF é obrigatório", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if((this.emitente.pais != "BR") && (!this.emitente.documento)) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "O documento é obrigatório", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if(!this.emitente.pais) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "O país é obrigatório", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if(!this.emitente.estado) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "O estado é obrigatório", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if((this.emitente.pais == "BR") && (!this.emitente.cidade)) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "A cidade é obrigatório", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if((this.emitente.pais == "BR") && (!this.emitente.bairro)) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "O bairro é obrigatório", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if((this.emitente.pais == "BR") && (!this.emitente.logradouro)) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "O logradouro é obrigatório", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if((this.emitente.pais == "BR") && (!this.emitente.cep)) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "O CEP é obrigatório", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if(!this.emitente.celular) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "O celular é obrigatório", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if(!this.emitente.email) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "O e-mail é obrigatório", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if((this.cadastraSenha) && (this.$refs.qualidadeSenha.obterQualidade() != 4)) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "A senha deve conter no mínimo 8 caracteres, sendo pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial.", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if((this.cadastraSenha) && (this.emitente.senha != this.emitente.senhaRepeticao)) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "A senha não confere com a repetição da senha.", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else {
        this.isLoading = true;
        let retorno = await this.incluirFluxoProjetoDireto({
          EMITENTE: this.emitente, 
          HASH: this.$route.params.hash
        });
        this.isLoading = false;

        if(retorno.RESULTADO) {
          this.isLoading = true;
          let fluxo = await this.obterFluxoDireto({
            ID_EMITENTE_ML: this.projetoWeb.ID_EMITENTE_ML, 
            ID_FLUXO_DE_CAIXA: retorno.FLUXO.ID_FLUXO_DE_CAIXA, 
            EMITENTE: this.emitente, 
            HASH: this.$route.params.hash
          });
          this.isLoading = false;

          if((fluxo) && (fluxo.FLUXO) && (fluxo.FLUXO.ID_FLUXO_DE_CAIXA)) {
            if(fluxo.FLUXO.DATA_PAGAMENTO) {
              this.$refs.alertBox.show(
                "success",
                "Sucesso",
                "",
                "",
                "Você já efetuou esse pagamento, Obrigado! A seguir você será direcionado a tela inicial do App."
              );
            } else {
              this.updatePendenciaPagar(fluxo.FLUXO);

              this.updateProjetoAcquisicaoDireta({
                ID_PROJETO_WEB: this.projetoWeb.ID_PROJETO_WEB, 
                CATEGORIA: this.projetoWeb.ID_EMITENTE_CATEGORIA, 
                EMITENTE: this.emitente
              });

              this.$router.push("/produtoCheckout/" + this.$route.params.hash);
            }
          } else {
            Swal.fire({
              icon: "error", 
              title: "Ocorreu um problema", 
              text: "Ocorreu um erro desconhecido. Tente novamente mais tarde.", 
              timer: 3000, 
              timerProgressBar: true
            });
          }
        } else if(retorno.MSG) {
          Swal.fire({
            icon: "error", 
            title: "Ocorreu um problema", 
            text: retorno.MSG, 
            timer: 3000, 
            timerProgressBar: true
          });
        } else {
          Swal.fire({
            icon: "error", 
            title: "Ocorreu um problema", 
            text: "Ocorreu um erro desconhecido. Tente novamente mais tarde.", 
            timer: 3000, 
            timerProgressBar: true
          });
        }
      }
    },
    async cpfValido() {
      if(this.emitente.cpf) {
        this.isLoading = true;
        let resultado = await this.getUserRegistered({
          CPF: this.emitente.cpf, 
          ID_EMITENTE_ML: this.projetoWeb.ID_EMITENTE_ML
        });
        this.isLoading = false;

        if(!resultado.registrado) {
          this.cadastraSenha = true;
        } else {
          this.cadastraSenha = false;
        }
      }
    },
    dismissAlert() {
      this.$router.push({
        name: "home"
      });
    }
  }
}
</script>

<style>

</style>