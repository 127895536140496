<template>
  <div class="group-input">
    <label>
      {{ label }} <span v-if="required" style="color: red">*</span>
    </label>
    <input 
      ref="input" 
      type="number" 
      v-model="valor" 
      :placeholder="placeholder" 
      :disabled="disabled"
    >
  </div>
</template>

<script>
export default {
  name: "InputNumber",
  props: [
    "modelValue", 
    "label", 
    "placeholder", 
    "value", 
    "disabled", 
    "required"
  ],
  computed: {
    valor: {
      get: function() {
        return (this.modelValue ? this.modelValue : this.value);
      },
      set: function(newValue) {
        this.$emit("update:modelValue", newValue);
      }
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    }
  }
}
</script>

<style>

</style>