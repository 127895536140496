<template>
  <div class="mt-7 login-section">
    <div class="tf-container">
      <div class="tf-form">
        <div style="text-align: center;">
          <img src="/images/paylogos_logo2.png" class="logo" />
        </div>
        <h1>
          Entrar
        </h1>
        <InputText 
          label="E-mail ou Celular" 
          placeholder="E-mail ou Celular" 
          v-model="usuario" 
          :disabled="isLoading"
        />
        <InputSenha 
          placeholder="Senha" 
          v-model="senha" 
          :disabled="isLoading" 
          @keyup.enter="entrar"
        />
        <a 
          href="javascript:;" 
          class="auth-forgot-password mt-3" 
          @click="esqueciSenha"
        >
          Esqueceu a Senha?
        </a>
        <ButtonPadrao 
          label="Entrar" 
          :isLoading="isLoading" 
          @click="entrar"
        />
      </div>
      <!--<p class="mb-9 fw-3 text-center">
        Ainda não tem uma conta?
        <a 
          href="javascript:;" 
          class="auth-link-rg" 
          @click="registrar"
        >
          Cadastre-se
        </a>
      </p>-->
    </div>
    <AlertBox ref="alertBox">
    </AlertBox>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import JwtService from "@/store/jwt.service";
import { mapActions, mapGetters } from "vuex";

import InputText from "@/components/inputs/InputText.vue";
import InputSenha from "@/components/inputs/InputSenha.vue";
import AlertBox from "@/components/AlertBox.vue";
import ButtonPadrao from "@/components/buttons/ButtonPadrao.vue";

export default {
  name: "LoginView",
  components: {
    InputText, 
    InputSenha, 
    AlertBox, 
    ButtonPadrao
  },
  data() {
    return {
      isLoading: false, 
      usuario: "", 
      senha: ""
    };
  },
  async mounted() {
    if((this.usuarioRealizarLogin) && (this.senhaRealizarLogin)) {
      this.usuario = this.usuarioRealizarLogin;
      this.senha = this.senhaRealizarLogin;

      this.atualizarUsuarioRealizarLogin("");
      this.atualizarSenhaRealizarLogin("");

      this.entrar();
    } else if(JwtService.getToken()) {
      let validacao = await this.getValidateToken();

      if(validacao.resultado) {
        this.$router.push({
          name: "home"
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      "usuarioRealizarLogin", 
      "senhaRealizarLogin"
    ])
  },
  methods: {
    ...mapActions([
      "realizarLogin", 
      "atualizarUsuarioRealizarLogin", 
      "atualizarSenhaRealizarLogin"
    ]),
    async entrar() {
      if((!this.usuario) || (!this.senha)) {
        this.$refs.alertBox.show(
          "error",
          "Erro",
          "",
          "Erro ao realizar login",
          "O e-mail ou celular, e a senha são obrigatórios."
        );
      } else {
        this.isLoading = true;

        let resultado = await this.realizarLogin({
          usuario: this.usuario, 
          senha: this.senha, 
          codigoConfirmacao: this.codigoConfirmacao
        });

        this.isLoading = false;

        if(resultado.resultado) {
          if(resultado.confirmado) {
            if(this.codigoConfirmacao) {
              Swal.fire({
                icon: "success", 
                title: "Sucesso", 
                text: "Sua conta foi confirmada com sucesso.",
                timer: 3000, 
                timerProgressBar: true
              });
            }

            this.$router.push({
              name: "home"
            });
          } else {
            this.confirmarEmailCelular = true;
            this.contadorReenviar = 60;
          }
        } else {
          this.$refs.alertBox.show(
            "error",
            "Erro",
            "",
            "",
            resultado.erro
          );
        }
      }
    },
    registrar() {
      this.$router.push({
        name: "register"
      });
    },
    esqueciSenha() {
      this.$router.push({
        name: "forgetPassword"
      });
    }
  }
}
</script>

<style scoped>
  .logo {
    width: 100%;
    max-width: 200px;
    margin-bottom: 30px;
  }
</style>