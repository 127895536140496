import axios from "axios";
import JwtService from "@/store/jwt.service";

const ApiService = {
  init() {
    // axios.defaults.baseURL = "http://localhost/backend-spirit/";
    axios.defaults.baseURL = "https://backend.micrologos.com.br/";
  },
  setHeader() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${JwtService.getToken()}`;
  },
  async query(resource, params) {
    return await axios.get(resource, params);
  },
  async get(resource, slug = "") {
    return await axios.get(`${resource}/${slug}`);
  },
  async getFile(resource, slug = "") {
    return await axios({
      url: resource + "/" + slug, 
      method: "GET", 
      responseType: "blob"
    });
  },
  async post(resource, params) {
    return await axios.post(`${resource}`, params);
  },
  async update(resource, slug, params) {
    return await axios.put(`${resource}/${slug}`, params);
  },
  async put(resource, params) {
    return await axios.put(`${resource}`, params);
  },
  async delete(resource) {
    return await axios.delete(resource);
  }
};

export default ApiService;