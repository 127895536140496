<template>
  <div class="mt-5">
    <div class="tf-container" v-if="isLoading" style="text-align: center;">
        <LoadingIcon size="150" />
      </div>
    <div class="tf-container" v-if="!isLoading">
      <div class="tf-title d-flex justify-content-between" style="margin-bottom: 10px;">
        <h3 class="fw_6">
          Suas próximas pendências (30 dias até hoje)
        </h3>
        <a href="javascript:;" class="primary_color fw_6" @click="acessarPendencias">
          Ver todos
        </a>
      </div>
      <div class="trading-month" v-for="fluxo in fluxos" :key="fluxo.MES + fluxo.ANO">
        <h4 class="fw_5 mb-3">
          {{ tituloFluxoMes(fluxo) }}
        </h4>
        <div class="group-trading-history mb-5" v-if="filtraFluxosPendentes(fluxo.FLUXOS).length">
          <a 
            class="tf-trading-history" 
            href="javascript:;" 
            v-for="fluxoItem in filtraFluxosPendentes(fluxo.FLUXOS)" 
            :key="fluxoItem.ID_FLUXO_DE_CAIXA" 
            @click="acessarPagamento(fluxoItem)" 
          >
            <div class="inner-left">
              <div class="icon-box rgba_primary">
                <img :src="'https://backend.micrologos.com.br/auth/logomarcaCliente/' + fluxoItem.ID_EMITENTE_ML" />
              </div>
              <div class="content">
                <h4>
                  {{ (fluxoItem.PROJETO ? fluxoItem.PROJETO : (fluxoItem.HISTORICO ? fluxoItem.HISTORICO : 'Sem Descrição')) }}
                  <span class="status" :class="{'status_success': fluxoItem.DATA_PAGAMENTO, 'status_critical': !fluxoItem.DATA_PAGAMENTO}">
                    {{ fluxoItem.DATA_PAGAMENTO ? 'Pago' : 'Pendente' }}
                  </span>
                </h4>
                <p>Vencimento: {{ retornaDataFormatada(fluxoItem.DATA_VENCIMENTO) }}</p>
                <p>{{ fluxoItem.EMITENTE_ML }}</p>
              </div>
            </div>
            <span class="num-val" :class="{'success_color': fluxoItem.DATA_PAGAMENTO, 'critical_color': !fluxoItem.DATA_PAGAMENTO}">
              {{ formataValorMonetario(fluxoItem.VALOR) }} <i class="icon-right" style="color: #1e1e1e;"></i>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { mapActions } from "vuex";

import LoadingIcon from "@/components/LoadingIcon.vue";

export default {
  name: "PendenciaLista", 
  components: {
    LoadingIcon
  },
  data() {
    return {
      isLoading: false, 
      fluxos: []
    };
  },
  mounted() {
    this.buscarHistorico();
  },
  methods: {
    ...mapActions(["listarFluxos", "updatePendenciaPagar"]), 
    acessarPendencias() {
      this.$router.push({
        name: "historico"
      });
    },
    async buscarHistorico() {
      this.isLoading = true;
      this.fluxos = await this.listarFluxos({
        DATA_INICIAL: moment().format("YYYY-MM-DD")
      });
      this.isLoading = false;
    },
    tituloFluxoMes(fluxo) {
      return moment(fluxo.ANO + "-" + fluxo.MES + "-01").format("MMMM YYYY").charAt(0).toUpperCase() + moment(fluxo.ANO + "-" + fluxo.MES + "-01").format("MMMM YYYY").slice(1);
    },
    retornaDataFormatada(data) {
      return moment(data, "YYYYMMDD[T]HHmmss").format("DD/MM/YYYY");
    },
    formataValorMonetario(valor) {
      return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    },
    acessarPagamento(fluxo) {
      this.updatePendenciaPagar(fluxo);

      this.$router.push({
        name: "pendencia"
      });
    },
    filtraFluxosPendentes(fluxos) {
      let fluxosPendentes = [];

      fluxos.forEach(fluxo => {
        if(!fluxo.DATA_PAGAMENTO) {
          fluxosPendentes.push(fluxo);
        }
      });

      return fluxosPendentes;
    }
  }
}
</script>

<style>

</style>