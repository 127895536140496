<template>
  <HeaderComponent 
    titulo="QR Code de Associação" 
    @voltar="voltar"
  />
  <div class="wrap-qr">
    <div class="tf-container">
      <h2 class="fw_6 text-center">Screen Your QRCode</h2>
      <div class="logo-qr">
        <div id="reader" style="width: 100%; height: 100%; max-height: 400px; max-width: 400px; margin: 0 auto;"></div>
      </div>
    </div>
  </div>
  <AlertBox ref="alertBox" @dismiss="dismissAlert">
  </AlertBox>
</template>

<script>
import { mapActions } from 'vuex';
import {Html5Qrcode} from "html5-qrcode";
import HeaderComponent from "@/components/template/HeaderComponent.vue";

import AlertBox from "@/components/AlertBox.vue";

export default {
  name: "QRCodeAssociar",
  data() {
    return {
      html5QrCodeInstance: null,
      qrcodeValue: ""
    };
  },
  components: {
    HeaderComponent,
    AlertBox
  },
  async mounted() {
    let devices = await Html5Qrcode.getCameras();

    if(devices && devices.length) {
      let vm = this;

      let cameraId = devices[0].id;
      this.html5QrCodeInstance = new Html5Qrcode("reader");
      this.html5QrCodeInstance.start(cameraId, {
        fps: 10,
        qrbox: {
          width: 250, 
          height: 250
        }
      }, async (decodedText) => {
        vm.qrcodeValue = decodedText;
        await vm.html5QrCodeInstance.stop();
        vm.associar();
      });
    } else {
      this.$refs.alertBox.show(
        "error",
        "Erro",
        "",
        "",
        "Não foi possível detectar câmeras disponíveis no seu dispositivo."
      );
    }
  },
  async beforeUnmount() {
    if(this.html5QrCodeInstance.getState() == 2) {
      await this.html5QrCodeInstance.stop();
    }
    this.html5QrCodeInstance = null;
  },
  methods: {
    ...mapActions(["associarEmitente"]), 
    voltar() {
      this.$router.push({
        name: "home"
      });
    },
    dismissAlert() {
      this.voltar();
    },
    async associar() {
      console.log(this.qrcodeValue);

      let dados = JSON.parse(this.qrcodeValue);

      if((dados != null) && (dados.tipo)) {
        if(dados.tipo == "associacao") {
          let resultado = await this.associarEmitente({
            QRCODE: dados
          });

          if(resultado.resultado) {
            this.$refs.alertBox.show(
              "success", 
              "Sucesso", 
              "", 
              "", 
              resultado.msg
            );
          } else {
            this.$refs.alertBox.show(
              "error", 
              "Erro", 
              "", 
              "", 
              resultado.msg
            );
          }
        } else {
          this.$refs.alertBox.show(
            "success",
            "Sucesso",
            "",
            "",
            "Não foi possível realizar a leitura do QRCode."
          );
        }
      } else {
        this.$refs.alertBox.show(
          "error",
          "Erro",
          "",
          "",
          "Não foi possível realizar a leitura do QRCode."
        );
      }
    }
  }
}
</script>

<style>

</style>