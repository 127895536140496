<template>
  <button 
    class="tf-btn accent large" 
    :disabled="(isLoading) || (disabled)"
  >
    {{ isLoading ? 'Aguarde por favor...' : label }} <LoadingIcon v-if="isLoading" size="38" />
  </button>
</template>

<script>
import LoadingIcon from "@/components/LoadingIcon.vue";

export default {
  name: "ButtonPadrao",
  components: {
    LoadingIcon
  },
  props: ["label", "isLoading", "disabled"]
}
</script>

<style scoped>

</style>