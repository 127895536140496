<template>
  <div class="group-input">
    <label>{{ label }}</label>
    <input 
      type="text" 
      v-maska 
      data-maska="#### #### #### ####" 
      v-model="valor" 
      :placeholder="placeholder" 
      :disabled="disabled"
    />
    <div class="credit-card">
      <i class="icon-bankgroup"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputCardNumber", 
  props: [
    "modelValue", 
    "label", 
    "placeholder", 
    "value", 
    "disabled"
  ],
  computed: {
    valor: {
      get: function() {
        return (this.modelValue ? this.modelValue : this.value);
      },
      set: function(newValue) {
        this.$emit("update:modelValue", newValue);
      }
    }
  }
}
</script>

<style>

</style>