<template>
  <TopoAplicacao />
  <CardPendencia 
    @acessarCartoes="acessarCartoes" 
  />
  <PendenciaLista />
  <ParceirosLista />
  <MenuInferior />

  <div class="modal fade" :class="{'show': modalInstallShow}" aria-modal="true" role="dialog" style="padding-right: 0px;" :style="{'display': modalInstallShow ? 'block' : 'none'}">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="heading">
          <h4 class="fw_6 text-center">
            Você gostaria de instalar o app Paylogos?
          </h4>
          <p class="fw_4 mt-2 text-center">Ao instalar o app você terá uma experiência mais abrangente e com mais recursos em seu dispositivo.</p>
        </div>
        <div class="bottom">
          <a href="javascript:;" class="secondary_color btn-hide-modal" @click="installApp(false)">
            Não Autorizar
          </a>
          <a href="javascript:;" class="primary_color btn-hide-modal" @click="installApp">
            Autorizar
          </a> 
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade" :class="{'show': modalInstallShow}" :style="{'display': modalInstallShow ? 'block' : 'none'}" @click="modalInstallShow = false"></div>
  <div class="modal fade" id="modalhome2">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="heading">
          <h2>Privacidade</h2>
          <p class="mt-1 fw_3">
            A mobile app privacy policy is a legal statement that must be clear, conspicuous, and consented to by all users. It must disclose how a mobile app gathers, stores, and uses the personally identifiable information it collects from its users.
            A mobile privacy app is developed and presented to users so that mobile app developers stay compliant with state, federal, and international laws. As a result, they fulfill the legal requirement to safeguard user privacy while protecting the company itself from legal challenges.
          </p>
          <h2 class="mt-3">Usuários Autorizados</h2>
          <p class="fw-3">
            A mobile app privacy policy is a legal statement that must be clear, conspicuous, and consented to by all users. It must disclose how a mobile app gathers, stores, and uses the personally identifiable information it collects from its users.
          </p>
          <div class="group-cb mt-3 align-items-center">
            <div class="cb">
              <input type="checkbox" class="tf-checkbox st1" checked>
            </div>
            <span class="fw_3">Concordo com o Termo de serviço e a Política de privacidade</span> 
          </div>
        </div>
        <div class="bottom mt-5">
          <a href="#" class="tf-btn accent large" data-dismiss="modal">Eu aceito</a>
        </div>
      </div>
    </div>
  </div>
  <PanelTopDownCartoes 
    ref="panelCartoes"
  />
  <PanelTopDownPINCadastro />
</template>

<script>
import JwtService from "@/store/jwt.service";
import { mapActions } from 'vuex';

import TopoAplicacao from "@/components/template/TopoAplicacao.vue";
import CardPendencia from "@/components/template/CardPendencia.vue";
import PendenciaLista from "@/components/template/PendenciaLista.vue";
import ParceirosLista from "@/components/template/ParceirosLista.vue";
import MenuInferior from "@/components/template/MenuInferior.vue";

import PanelTopDownCartoes from "@/components/PanelTopDownCartoes.vue";
import PanelTopDownPINCadastro from "@/components/PanelTopDownPINCadastro.vue";

export default {
  name: "HomeView", 
  data() {
    return {
      modalInstallShow: false
    };
  },
  components: {
    TopoAplicacao, 
    CardPendencia, 
    PendenciaLista, 
    ParceirosLista, 
    MenuInferior, 
    PanelTopDownCartoes, 
    PanelTopDownPINCadastro
  },
  mounted() {
    this.updatePendenciaPagar({});

    this.emitter.on("beforeinstallprompt", () => {
      this.checkIfInstall();
    });

    this.checkIfInstall();
  },
  methods: {
    ...mapActions(["updatePendenciaPagar"]),
    acessarCartoes() {
      this.$refs.panelCartoes.exibirOpcoes();
    },
    checkIfInstall() {
      if(window.installPrompt) {
        let promptInstalacaoExibido = JwtService.criarObterGenericoComValidade("promptInstalacao", false);

        if(!promptInstalacaoExibido) {
          this.modalInstallShow = true;
          JwtService.salvarGenericoComValidade("promptInstalacao", true);
        }
      }
    },
    async installApp() {
      const result = await window.installPrompt.prompt();
      if (result.outcome === "accepted") {
        this.modalInstallShow = false;
      } else {
        this.modalInstallShow = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  max-width: 273px;

  .heading {
      border-bottom: 1px solid #dddddd;
      padding: 16px;
      h4 {
          padding: 0px 30px;
      }
  }

  .bottom {
      display: grid;
      grid-template-columns: 1fr 1fr;
      a {
          padding: 11px 16px;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          &:first-child {
              border-right: 1px solid #dddddd;
          }
      }
  }
}
</style>