<template>
  <div class="header mb-1" :class="{'is-fixed': !nofixed}">
    <div class="tf-container">
      <div class="tf-statusbar d-flex justify-content-center align-items-center">
        <a class="back-btn" href="javascript:;" @click="voltar">
          <i class="icon-left"></i>
        </a>
        <h3>{{ titulo }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  props: ["titulo", "nofixed"],
  methods: {
    voltar() {
      this.$emit("voltar");
    }
  }
}
</script>

<style>

</style>