<template>
  <HeaderComponent 
    titulo="Forma de Pagamento" 
    @voltar="voltar"
  />
  <div id="app-wrap">
    <div class="transfer-section">
      <div class="tf-container">
        <div class="tf-balance-box transfer-amount mt-3">
          <div class="top">
            <i class="icon-group-dollar"></i>
            <h1>
              {{ formataValorMonetario(pendencia.VALOR) }}
            </h1>
            <h4 class="secondary_color fw_4">
              {{ (pendencia.PROJETO ? pendencia.PROJETO : (pendencia.HISTORICO ? pendencia.HISTORICO : 'Sem Descrição')) }} {{ retornaDataFormatada(pendencia.DATA_VENCIMENTO) }}
            </h4>
          </div>
          <div class="bottom d-flex justify-content-between align-items-center">
            <h4 class="secondary_color fw_4">
              Taxa de transação
            </h4>
            <h4>
              <a href="javascript:;">
                Grátis
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div v-if="(!isLoading) && (etapa == 0)" class="transfer-card">
      <div class="tf-container">
        <div 
          class="tf-card-block d-flex align-items-center justify-content-between" 
          @click="selecionarFormaPagamento('P')" 
          style="cursor: pointer;"
        >
          <div class="inner d-flex align-items-center">
            <div class="logo-img" style="text-align: center;">
              <img src="/images/logo-banks/pix.png" alt="image" style="max-width: 100%; max-height: 100%; width: initial;" />
            </div>
            <div class="content">
              <h4>
                <a href="javascript:;" class="fw_6">PIX</a>
              </h4>
              <p>Pagamento Instantâneo</p>
            </div>
          </div>
          <input type="radio" name="radio" v-model="formaPagamento" value="P">
        </div>
        <div 
          class="tf-card-block d-flex align-items-center justify-content-between" 
          @click="selecionarFormaPagamento('B')" 
          style="cursor: pointer;"
        >
          <div class="inner d-flex align-items-center">
            <div class="logo-img" style="text-align: center;">
              <img src="/images/logo-banks/boleto.png" alt="image" style="max-width: 100%; max-height: 100%; width: initial;" />
            </div>
            <div class="content">
              <h4>
                <a href="javascript:;" class="fw_6">Boleto Bancário</a>
              </h4>
              <p>Confirmado em até 3 dias úteis</p>
            </div>
          </div>
          <input type="radio" name="radio" v-model="formaPagamento" value="B">
        </div>
        <div 
          class="tf-card-block d-flex align-items-center justify-content-between" 
          @click="selecionarFormaPagamento('C')" 
          style="cursor: pointer;"
        >
          <div class="inner d-flex align-items-center">
            <div class="logo-img" style="text-align: center;">
              <img src="/images/logo-banks/creditcard.png" alt="image" style="max-width: 100%; max-height: 100%; width: initial;" />
            </div>
            <div class="content">
              <h4>
                <a href="javascript:;" class="fw_6">
                  Cartão de Crédito
                </a>
              </h4>
              <p>Pagamento Instantâneo</p>
            </div>
            <div v-if="formaPagamento == 'C'" class="content">
              <InputCardNumber 
                label="Número do Cartão" 
                placeholder="Número do Cartão" 
                v-model="cartao.NUMERO_CARTAO" 
                :disabled="isLoading"
              />
              <InputText 
                label="Nome no Cartão" 
                placeholder="Nome no Cartão" 
                v-model="cartao.NOME_CARTAO" 
                :disabled="isLoading"
              />
              <div class="group-double-ip">
                <InputDate 
                  label="Data de Expiração" 
                  v-model="cartao.DATA_EXPIRACAO" 
                  :disabled="isLoading" 
                  type="month"
                />
                <InputText 
                  label="CVV" 
                  placeholder="CVV" 
                  v-model="cartao.CODIGO_SEGURANCA" 
                  :disabled="isLoading"
                />
              </div>
            </div>
          </div>
          <input type="radio" name="radio" v-model="formaPagamento" value="C">
        </div>
        <div v-if="(formaPagamento != 'P') && (formaPagamento != 'B')" class="tf-spacing-20"></div>
        <InputSelect 
          v-if="(formaPagamento != 'P') && (formaPagamento != 'B')" 
          label="Parcelas" 
          v-model="parcelas" 
          :items="formataValoresParcelas(pendencia.VALOR)" 
          :disabled="isLoading"
        />
      </div>
      <div class="bottom-navigation-bar">
        <div class="tf-container">
          <a href="javascript:;" id="btn-popup-down" class="tf-btn accent large" @click="efetuarTransacaoLocal">
            Confirmar Transação
          </a>
        </div>
      </div>
    </div>
    <div v-if="(!isLoading) && (etapa == 1)" class="transfer-card">
      <div class="tf-container">
        <div class="tf-card-block align-items-center justify-content-between" style="text-align: center;">
          <h1 style="font-size: 28px;">PIX</h1>
          <qrcode-vue :value="pix" :size="250" style="margin-top: 8px;"></qrcode-vue>
          <div style="margin: 12px auto; max-width: 500px;">
            {{ pix }}
          </div>
          <a v-if="pixExpiracaoFormatada != '00:00'" href="javascript:;" class="tf-btn accent large" @click="copiarPIX">
            PIX Copia e Cola
          </a>
          <a v-if="pixExpiracaoFormatada == '00:00'" href="javascript:;" class="tf-btn accent large" @click="voltar">
            Voltar
          </a>
        </div>
        <div class="tf-card-block align-items-center justify-content-between" style="text-align: center;">
          <span v-if="pixExpiracaoFormatada != '00:00'" style="font-size: 13px;">
            Expiração em {{ pixExpiracaoFormatada }} minutos
          </span>
          <span v-if="pixExpiracaoFormatada == '00:00'" style="font-size: 13px; color: #ea3434;">
            PIX expirado, selecione "Voltar" e refaça a operação
          </span>
        </div>
      </div>
    </div>
    <div v-if="(!isLoading) && (etapa == 2)" class="transfer-card">
      <div class="tf-container">
        <div class="tf-card-block align-items-center justify-content-between" style="text-align: center;">
          <h1 style="font-size: 28px;">Boleto Bancário</h1>
          <div style="margin: 12px auto; max-width: 500px; font-size: 13px; font-weight: 700;">
            Linha Digitável: {{ boletoCodigoBarra }}
          </div>
          <a href="javascript:;" class="tf-btn accent large" @click="acessarBoleto">
            Acessar Boleto
          </a>
          <a href="javascript:;" class="tf-btn accent large" @click="voltar" style="margin-top: 12px;">
            Voltar
          </a>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="transfer-card">
      <div class="tf-container">
        <div class="tf-card-block align-items-center justify-content-between" style="text-align: center;">
          <h1 style="font-size: 28px;">Carregando...</h1>
          <LoadingIcon size="100" />
        </div>
      </div>
    </div>
  </div>
  <AlertBox ref="alertBox" @dismiss="dismissAlert">
  </AlertBox>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";
import QrcodeVue from "qrcode.vue";

import { mapGetters, mapActions } from "vuex";

import HeaderComponent from "@/components/template/HeaderComponent.vue";

import InputCardNumber from "@/components/inputs/InputCardNumber.vue";
import InputSelect from "@/components/inputs/InputSelect.vue";
import InputText from "@/components/inputs/InputText.vue";
import InputDate from "@/components/inputs/InputDate.vue";

import AlertBox from "@/components/AlertBox.vue";

import LoadingIcon from "@/components/LoadingIcon.vue";

export default {
  name: "ProdutoCheckoutView",
  components: {
    QrcodeVue, 
    HeaderComponent, 
    InputCardNumber, 
    InputSelect, 
    InputText, 
    InputDate, 
    AlertBox, 
    LoadingIcon
  },
  data() {
    return {
      isLoading: false, 
      projetoWeb: {}, 
      pendencia: {}, 
      cartao: {
        NUMERO_CARTAO: "", 
        NOME_CARTAO: "", 
        DATA_EXPIRACAO: "", 
        CODIGO_SEGURANCA: "", 
        MES_EXPIRACAO: "", 
        ANO_EXPIRACAO: ""
      },
      formaPagamento: "P", 
      parcelas: "1", 
      etapa: 0, 
      transacao: {}, 
      pix: "", 
      pixExpiracao: "", 
      pixExpiracaoFormatada: "", 
      boletoUrl: "", 
      boletoCodigoBarra: ""
    };
  },
  async mounted() {
    this.etapa = 0;
    document.body.classList.add("bg_surface_color");

    if(this.$route.params.hash) {
      this.projetoWeb = await this.obterProjetoWebHash(this.$route.params.hash);
    }
    
    if((this.pendenciaPagar) && (this.pendenciaPagar.ID_FLUXO_DE_CAIXA) && (this.projetoAcquisicaoDireta) && (this.projetoAcquisicaoDireta.EMITENTE)) {
      this.isLoading = true;

      this.pendencia = this.pendenciaPagar;

      /*let transacaoPendente = await this.obterTransacaoPendenteBoleto({
        ID_EMITENTE_ML: this.pendencia.ID_EMITENTE_ML, 
        ID_FLUXO_DE_CAIXA: this.pendencia.ID_FLUXO_DE_CAIXA
      });

      if(transacaoPendente.ID_ZOOP_TRANSACAO) {
        this.etapa = 2;
        if(transacaoPendente.HASH) {
          this.transacao = transacaoPendente;
          this.boletoUrl = "https://backend.micrologos.com.br/paylogosAppAccess/acessarBoleto/" + transacaoPendente.HASH;
          this.boletoCodigoBarra = transacaoPendente.BOLETO_CODIGO_BARRA;
        }
      }*/

      this.isLoading = false;
    } else {
      this.$router.push("/produto/" + this.$route.params.hash);
    }
  },
  unmounted() {
    document.body.classList.remove("bg_surface_color");
  },
  computed: {
    ...mapGetters([
      "pendenciaPagar", 
      "projetoAcquisicaoDireta"
    ])
  },
  methods: {
    ...mapActions([
      "efetuarTransacaoDireta", 
      "verificarTransacaoDireta", 
      "obterTransacaoPendenteBoleto", 
      "obterProjetoWebHash"
    ]), 
    voltar() {
      this.$router.push("/produto/" + this.$route.params.hash);
    }, 
    formataValorMonetario(valor, parcelas = 1) {
      if(valor) {
        let valorAux = parseFloat((parseFloat(valor) / parcelas).toFixed(2));
        return valorAux.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
      } else {
        return "";
      }
    },
    formataValoresParcelas(valor) {
      return [
        {value: '1', text: (this.formataValorMonetario(valor) + ' à vista')}, 
        {value: '2', text: ('2 parcelas de ' + this.formataValorMonetario(valor, 2))}, 
        {value: '3', text: ('3 parcelas de ' + this.formataValorMonetario(valor, 3))}, 
        {value: '4', text: ('4 parcelas de ' + this.formataValorMonetario(valor, 4))}, 
        {value: '5', text: ('5 parcelas de ' + this.formataValorMonetario(valor, 5))}, 
        {value: '6', text: ('6 parcelas de ' + this.formataValorMonetario(valor, 6))}, 
        {value: '7', text: ('7 parcelas de ' + this.formataValorMonetario(valor, 7))}, 
        {value: '8', text: ('8 parcelas de ' + this.formataValorMonetario(valor, 8))}, 
        {value: '9', text: ('9 parcelas de ' + this.formataValorMonetario(valor, 9))}, 
        {value: '10', text: ('10 parcelas de ' + this.formataValorMonetario(valor, 10))}, 
        {value: '11', text: ('11 parcelas de ' + this.formataValorMonetario(valor, 11))}, 
        {value: '12', text: ('12 parcelas de ' + this.formataValorMonetario(valor, 12))}
      ];
    },
    retornaDataFormatada(data) {
      return moment(data, "YYYYMMDD[T]HHmmss").format("DD/MM/YYYY");
    },
    formataNumeroCartao(numero) {
      return "**** **** **** " + numero.replace(/[\D]/g, '');
    }, 
    selecionarFormaPagamento(forma) {
      this.formaPagamento = forma;
    }, 
    async efetuarTransacaoLocal() {
      this.isLoading = true;

      if((this.formaPagamento == "C") && (!this.cartao.NUMERO_CARTAO)) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "O número do cartão é obrigatório", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if((this.formaPagamento == "C") && (!this.cartao.NOME_CARTAO)) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "O nome no cartão é obrigatório", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if((this.formaPagamento == "C") && (!this.cartao.DATA_EXPIRACAO)) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "A data de expiração é obrigatória", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else if((this.formaPagamento == "C") && (!this.cartao.CODIGO_SEGURANCA)) {
        Swal.fire({
          icon: "warning", 
          title: "Aviso", 
          text: "O CVV é obrigatório", 
          showConfirmButton: false, 
          timer: 3000
        });
      } else {
        if(this.formaPagamento == "C") {
          this.cartao.MES_EXPIRACAO = this.cartao.DATA_EXPIRACAO.split("-")[1];
          this.cartao.ANO_EXPIRACAO = this.cartao.DATA_EXPIRACAO.split("-")[0];
          this.cartao.NUMERO_CARTAO = this.cartao.NUMERO_CARTAO.replace(/[\D]/g, '');
        }

        console.log(this.projetoAcquisicaoDireta);

        let resposta = await this.efetuarTransacaoDireta({
          ID_EMITENTE_ML: this.projetoWeb.ID_EMITENTE_ML, 
          ID_FLUXO_DE_CAIXA: this.pendencia.ID_FLUXO_DE_CAIXA, 
          PARCELAS: this.parcelas, 
          TIPO: this.formaPagamento, 
          CARTAO: this.cartao, 
          HASH: this.$route.params.hash, 
          EMITENTE: this.projetoAcquisicaoDireta.EMITENTE
        });

        if(resposta.RESULTADO) {
          if((this.formaPagamento != "P") && (this.formaPagamento != "B")) {
            this.$refs.alertBox.show(
              "success",
              "Sucesso",
              "",
              "",
              "Sua transação foi paga com sucesso. Você receberá um e-mail com os detalhes da transação, Obrigado! A seguir você será direcionado a tela inicial do App."
            );
          } else if(this.formaPagamento == "P") {
            this.etapa = 1;
            if((resposta.TRANSACAO) && (resposta.TRANSACAO.PIX_QRCODE)) {
              this.transacao = resposta.TRANSACAO;
              this.pix = resposta.TRANSACAO.PIX_QRCODE;
              this.pixExpiracao = resposta.TRANSACAO.PIX_DATA_EXPIRACAO;
              this.atualizacaoHoraExpiracao();
              
              setTimeout(() => {
                this.verificarTransacaoLocal();
              }, 5000);
            }
          } else if(this.formaPagamento == "B") {
            this.etapa = 2;
            if((resposta.TRANSACAO) && (resposta.TRANSACAO.HASH)) {
              this.transacao = resposta.TRANSACAO;
              this.boletoUrl = "https://backend.micrologos.com.br/paylogosAppAccess/acessarBoleto/" + resposta.TRANSACAO.HASH;
              this.boletoCodigoBarra = resposta.TRANSACAO.BOLETO_CODIGO_BARRA;
            }
          } else {
            this.$refs.alertBox.show(
              "error",
              "Erro",
              "",
              "",
              "Ocorreu um erro ao efetuar a transação. Tente novamente mais tarde."
            );
          }
        } else {
          if((resposta) && (resposta.MSG)) {
            if(resposta.MSG == "The card number is not a valid credit card number.") {
              resposta.MSG = "O número do cartão não é válido.";
            }
          }
          
          await Swal.fire({
            icon: "error", 
            title: "Erro", 
            text: resposta.MSG ? resposta.MSG : "Ocorreu um erro ao efetuar a transação.", 
            showConfirmButton: false, 
            timer: 3000
          });
        }
      }

      this.isLoading = false;
    },
    dismissAlert() {
      this.$router.push({
        name: "home"
      });
    }, 
    copiarPIX() {
      navigator.clipboard.writeText(this.pix).then(() => {
        Swal.fire({
          icon: "success", 
          title: "Sucesso", 
          text: "PIX copiado com sucesso.", 
          timer: 3000, 
          timerProgressBar: true
        });
      }, () => {
        Swal.fire({
          icon: "error", 
          title: "Erro", 
          text: "Ocorreu um erro ao copiar o PIX.", 
          timer: 3000, 
          timerProgressBar: true
        });
      });
    }, 
    atualizacaoHoraExpiracao() {
      let expirationDate = moment(moment.utc(this.pixExpiracao, "YYYY-MM-DD HH:mm:ss").toDate());
      let duration = moment.duration(expirationDate.diff(moment()));
      if(duration.asSeconds() > 0) {
        this.pixExpiracaoFormatada = moment.utc(duration.asMilliseconds()).format("mm:ss");
        setTimeout(() => {
          this.atualizacaoHoraExpiracao();
        }, 1000);
      } else {
        this.pixExpiracaoFormatada = "00:00";
      }

      return duration;
    },
    async verificarTransacaoLocal() {
      let expiracao = this.atualizacaoHoraExpiracao();
      if(expiracao.asSeconds() > 0) {
        let transacao = await this.verificarTransacaoDireta({
          ID_ZOOP_TRANSACAO: this.transacao.ID_ZOOP_TRANSACAO, 
          HASH: this.$route.params.hash, 
          EMITENTE: this.projetoAcquisicaoDireta.EMITENTE
        });
        if(transacao.STATUS == "succeeded") {
          this.$refs.alertBox.show(
            "success",
            "Sucesso",
            "",
            "",
            "Sua transação foi paga com sucesso. Você receberá um e-mail com os detalhes da transação, Obrigado! A seguir você será direcionado a tela inicial do App."
          );
        } else {
          setTimeout(() => {
            this.verificarTransacaoLocal();
          }, 5000);
        }
      }
    },
    acessarBoleto() {
      if(this.boletoUrl) {
        window.open(this.boletoUrl);
      }
    }
  }
}
</script>

<style scoped>
  .status {
    display: inline-block;
    font-size: 10px;
    color: white;
    border-radius: 10px;
    padding-left: 4px;
    padding-right: 4px;
    line-height: 15px;
    vertical-align: middle;
    margin-left: 6px;
    margin-bottom: 3px;
  }
  
  .status_success {
    background-color: #44b461;
  }
</style>