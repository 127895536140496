<template>
  <div class="group-input auth-pass-input last">
    <label>
      {{ label ? label : 'Senha' }} <span v-if="required" style="color: red">*</span>
    </label>
    <input 
      class="password-input" 
      :type="campoSenhaTipo" 
      v-model="valor" 
      :placeholder="placeholder" 
      :disabled="disabled"
    />
    <a 
      class="icon-eye password-addon" 
      @click="alterarCampoSenhaTipo" 
      style="cursor: pointer;"
    ></a>
  </div>
</template>

<script>
export default {
  name: "InputSenha", 
  data() {
    return {
      campoSenhaTipo: "password"
    };
  },
  props: [
    "modelValue", 
    "label", 
    "placeholder", 
    "value", 
    "disabled", 
    "required"
  ],
  computed: {
    valor: {
      get: function() {
        return (this.modelValue ? this.modelValue : this.value);
      },
      set: function(newValue) {
        this.$emit("update:modelValue", newValue);
      }
    }
  },
  methods: {
    alterarCampoSenhaTipo() {
      if (this.campoSenhaTipo == "password") {
        this.campoSenhaTipo = "text";
      } else {
        this.campoSenhaTipo = "password";
      }
    }
  }
}
</script>

<style>

</style>