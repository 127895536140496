<template>
  <HeaderComponent 
    :titulo="etapaBuscarCPF ? 'Primeiro acesso | Registre-se' : 'Cadastre-se'" 
    @voltar="voltar"
  />
  <div class="register-section" id="app-wrap">
    <div class="tf-container" style="margin-top: 26px;">
      <div class="tf-form" v-if="!confirmarEmailCelular">
        <div v-if="idEmitenteML" style="text-align: center; margin-bottom: 30px;">
          <img :src="'https://backend.micrologos.com.br/auth/logomarcaCliente/' + idEmitenteML" class="logo" style="max-width: 300px; width: auto;" />
        </div>
        <InputText 
          v-if="!etapaBuscarCPF" 
          label="Nome Completo" 
          placeholder="Nome Completo" 
          v-model="emitente.nome" 
          :disabled="isLoading"
        />
        <InputCPF 
          placeholder="CPF" 
          v-model="emitente.cpf" 
          :disabled="(isLoading) || (!etapaBuscarCPF)" 
          @keyup="verificaBuscaCPF"
        />
        <InputText 
          v-if="!etapaBuscarCPF" 
          label="Nome Profissional - Visível para o paciente" 
          placeholder="Nome Profissional" 
          v-model="emitente.nome_apresentacao" 
          :disabled="isLoading"
        />
        <InputText 
          v-if="!etapaBuscarCPF" 
          label="CRM - número de registro do médico - Visível para o paciente" 
          placeholder="CRM" 
          v-model="emitente.conselho_numero" 
          :disabled="isLoading"
        />
        <InputText 
          v-if="!etapaBuscarCPF" 
          label="RQE - registro qualificação especialidade - Visível para o paciente" 
          placeholder="RQE" 
          v-model="emitente.rqe" 
          :disabled="isLoading"
        />
        <InputDate 
          v-if="!etapaBuscarCPF" 
          label="Data de Nascimento" 
          v-model="emitente.dataNascimento" 
          :disabled="isLoading"
        />
        <InputText 
          v-if="!etapaBuscarCPF" 
          label="E-mail" 
          placeholder="E-mail" 
          v-model="emitente.email" 
          :disabled="isLoading"
        />
        <InputCelular 
          v-if="!etapaBuscarCPF" 
          placeholder="Celular" 
          v-model="emitente.celular" 
          :disabled="isLoading"
        />
        <InputSelect 
          v-if="!etapaBuscarCPF" 
          label="Sexo" 
          v-model="emitente.sexo" 
          :items="[
            {value: 'M', text: 'Masculino'}, 
            {value: 'F', text: 'Feminino'}
          ]" 
          :disabled="isLoading"
        />
        <InputCEP 
          v-if="!etapaBuscarCPF" 
          placeholder="CEP" 
          v-model="emitente.cep" 
          @busca="buscaCEP" 
          :disabled="isLoading"
        />
        <InputText 
          v-if="!etapaBuscarCPF" 
          label="Logradouro" 
          placeholder="Logradouro" 
          v-model="emitente.logradouro" 
          :disabled="isLoading"
        />
        <InputNumber 
          v-if="!etapaBuscarCPF" 
          label="Logradouro Número" 
          placeholder="Logradouro Número" 
          v-model="emitente.logradouroNumero" 
          :disabled="isLoading"
        />
        <InputText 
          v-if="!etapaBuscarCPF" 
          label="Logradouro Complemento" 
          placeholder="Logradouro Complemento" 
          v-model="emitente.logradouroComplemento" 
          :disabled="isLoading"
        />
        <InputText 
          v-if="!etapaBuscarCPF" 
          label="Bairro" 
          placeholder="Bairro" 
          v-model="emitente.bairro" 
          :disabled="isLoading"
        />
        <InputText 
          v-if="!etapaBuscarCPF" 
          label="Cidade" 
          placeholder="Cidade" 
          v-model="emitente.cidade" 
          :disabled="isLoading"
        />
        <InputEstado 
          v-if="!etapaBuscarCPF" 
          v-model="emitente.estado" 
          :disabled="isLoading"
        />
<!--        <InputText
          v-if="!etapaBuscarCPF" 
          label="Facebook - Visível para o paciente" 
          placeholder="Facebook" 
          v-model="emitente.facebook"
          :disabled="isLoading"
        />
        <InputText
          v-if="!etapaBuscarCPF" 
          label="Instagram - Visível para o paciente" 
          placeholder="Instagram" 
          v-model="emitente.instagram"
          :disabled="isLoading"
        />
        <InputText
          v-if="!etapaBuscarCPF" 
          label="X Twitter - Visível para o paciente" 
          placeholder="X Twitter" 
          v-model="emitente.x"
          :disabled="isLoading"
        />
        <InputText
          v-if="!etapaBuscarCPF" 
          label="Linkedin - Visível para o paciente" 
          placeholder="Linkedin" 
          v-model="emitente.linkedin"
          :disabled="isLoading"
        />
        <InputText
          v-if="!etapaBuscarCPF" 
          label="Link para Currículo Lattes - Visível para o paciente" 
          placeholder="Currículo Lattes" 
          v-model="emitente.lattes"
          :disabled="isLoading"
        /> -->
        <hr v-if="!etapaBuscarCPF" />
        <InputSenha 
          v-if="!etapaBuscarCPF" 
          placeholder="Senha" 
          v-model="emitente.senha" 
          style="margin-top: 28px;" 
          :disabled="isLoading"
        />
        <QualidadeSenha 
          v-if="!etapaBuscarCPF" 
          ref="qualidadeSenha" 
          :senha="emitente.senha" 
          style="margin-top: 8px;"
        />
        <InputSenha 
          v-if="!etapaBuscarCPF" 
          label="Repita a Senha" 
          placeholder="Repita a Senha" 
          v-model="emitente.senhaRepeticao" 
          style="margin-top: 20px;" 
          :disabled="isLoading"
        />
        <InputCheckbox 
          v-if="!etapaBuscarCPF" 
          label="Eu concordo com os termos de uso e política de privacidade" 
          v-model="emitente.aceiteTermos" 
          style="margin-bottom: 25px"
          :disabled="isLoading"
        />
        <InputRecaptcha 
          ref="recaptchaCadastrese" 
          v-if="!etapaBuscarCPF"
        />
        <div class="group-btn-change-name">
          <ButtonPadrao 
            v-if="etapaBuscarCPF" 
            label="Buscar" 
            :isLoading="isLoading" 
            @click="buscarCadastro"
          />
          <ButtonPadrao 
            v-if="!etapaBuscarCPF" 
            label="Salvar" 
            :isLoading="isLoading" 
            @click="salvar"
          />
        </div>
        <div style="text-align: center; margin-top: 30px;">
          <p v-if="!mostraPrecisoAjuda" style="font-size: 14px;">
            <a href="javascript:;" @click="mostraPrecisoAjuda = true">Preciso de ajuda para me registrar</a>
          </p>
          <iframe 
            v-if="mostraPrecisoAjuda" 
            src="https://www.youtube.com/embed/WOxlDkHWyq0?si=UJXRzckmJOAvdgJS" 
            title="Primeiro Acesso App Paylogos" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen 
            style="max-width: 100%; width: 560px; height: 315px;"
          ></iframe>
        </div>
      </div>
      <div class="tf-form tf-form-verify" v-if="confirmarEmailCelular">
        <h1 class="text-center">
          Confirmar sua Conta
        </h1>
        <p class="mb-7 fw-3 text-center">
          Um código de confirmação foi enviado para {{ opcaoEnviada.alvo }}. Digite o código a baixo:
        </p>
        <div class="d-flex group-input-verify">
          <input 
            ref="codigoConfirmacao1" 
            v-model="codigoConfirmacao1" 
            type="tel" 
            maxlength="1" 
            pattern="[0-9]" 
            class="input-verify" 
            @keyup="focusInput($event, 2)" 
            @paste="pasteCodigoConfirmacao"
          />
          <input 
            ref="codigoConfirmacao2" 
            v-model="codigoConfirmacao2" 
            type="tel" 
            maxlength="1" 
            pattern="[0-9]" 
            class="input-verify" 
            @keyup="focusInput($event, 3)"
          />
          <input 
            ref="codigoConfirmacao3" 
            v-model="codigoConfirmacao3" 
            type="tel" 
            maxlength="1" 
            pattern="[0-9]" 
            class="input-verify" 
            @keyup="focusInput($event, 4)"
          />
          <input 
            ref="codigoConfirmacao4" 
            v-model="codigoConfirmacao4" 
            type="tel" 
            maxlength="1" 
            pattern="[0-9]" 
            class="input-verify" 
            @keyup="focusInput($event, 5)"
          />
          <input 
            ref="codigoConfirmacao5" 
            v-model="codigoConfirmacao5" 
            type="tel" 
            maxlength="1" 
            pattern="[0-9]" 
            class="input-verify" 
            @keyup="focusInput($event, 6)"
          />
          <input 
            ref="codigoConfirmacao6" 
            v-model="codigoConfirmacao6" 
            type="tel" 
            maxlength="1" 
            pattern="[0-9]" 
            class="input-verify" 
            @keyup="focusInput($event, 7)"
          />
        </div>
        <div class="text-send-code">
          <p class="fw_4">
            Um código de 6 dígitos foi enviado via SMS e E-mail.
          </p>
          <p v-if="contadorReenviar > 0" class="primary_color fw_7">
            Reenviar em &nbsp;<span class="js-countdown">{{ contadorReenviar }}</span>&nbsp;segundos
          </p>
          <p v-if="contadorReenviar == 0" class="primary_color fw_7">
            <a 
              href="javascript:;" 
              class="auth-link-rg" 
              @click="reenviarCodigoConfirmacao"
            >
              Reenviar um novo código
            </a>
          </p>
        </div>
        <div class="bottom-navigation-bar bottom-btn-fixed">
          <ButtonPadrao 
            label="Continuar" 
            :isLoading="isLoading" 
            @click="verificarCodigoConfirmacaoLocal"
          />
        </div>
      </div>
    </div>
    <AlertBox ref="alertBox" @dismiss="dismissAlert">
    </AlertBox>
  </div>
  <PanelTopDown 
    ref="panelTopDown" 
    title="Clique em uma das opções abaixo para receber o código de confirmação" 
    @selecionado="enviarConfirmacao"
  />
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import JwtService from "@/store/jwt.service";

import HeaderComponent from "@/components/template/HeaderComponent.vue";

import InputText from "@/components/inputs/InputText.vue";
import InputCPF from "@/components/inputs/InputCPF.vue";
import InputDate from "@/components/inputs/InputDate.vue";
import InputCelular from "@/components/inputs/InputCelular.vue";
import InputSelect from "@/components/inputs/InputSelect.vue";
import InputCEP from "@/components/inputs/InputCEP.vue";
import InputNumber from "@/components/inputs/InputNumber.vue";
import InputEstado from "@/components/inputs/InputEstado.vue";
import InputSenha from "@/components/inputs/InputSenha.vue";
import InputCheckbox from "@/components/inputs/InputCheckbox.vue";
import InputRecaptcha from "@/components/inputs/InputRecaptcha.vue";

import AlertBox from "@/components/AlertBox.vue";
import QualidadeSenha from "@/components/QualidadeSenha.vue";
import PanelTopDown from "@/components/PanelTopDown.vue";

import ButtonPadrao from "@/components/buttons/ButtonPadrao.vue";

export default {
  name: "CadastreView",
  components: {
    HeaderComponent, 
    InputText, 
    InputCPF, 
    InputDate, 
    InputCelular, 
    InputSelect, 
    InputCEP, 
    InputNumber, 
    InputEstado, 
    InputSenha, 
    InputCheckbox, 
    InputRecaptcha, 
    AlertBox, 
    QualidadeSenha, 
    PanelTopDown, 
    ButtonPadrao
  },
  data() {
    return {
      isLoading: false, 
      mostraPrecisoAjuda: false, 
      idEmitenteML: "", 
      etapaBuscarCPF: true, 
      confirmarEmailCelular: false, 
      codigoConfirmacao1: "",
      codigoConfirmacao2: "",
      codigoConfirmacao3: "",
      codigoConfirmacao4: "",
      codigoConfirmacao5: "",
      codigoConfirmacao6: "",
      codigoConfirmacao: "",
      contadorReenviar: 0,
      timerContadorReenviar: null, 
      opcaoEnviada: {}, 
      emitente: {
        idEmitenteML: "", 
        nome: "", 
        nome_apresentacao: "",
        cpf: "", 
        dataNascimento: "", 
        email: "", 
        celular: "", 
        sexo: "0", 
        cep: "", 
        logradouro: "", 
        logradouroNumero: "", 
        logradouroComplemento: "", 
        bairro: "", 
        cidade: "", 
        estado: "0", 
        facebook: "",
        instagram: "",
        x: "",
        linkedin: "",
        lattes: "",
        senha: "", 
        senhaRepeticao: "", 
        aceiteTermos: false, 
        recaptcha: "", 
        codigoConfirmacao: "", 
        codigoConfirmacaoTipo: ""
      }
    };
  },
  async mounted() {
    this.iniciarContadorReenviar();

    if(JwtService.getToken()) {
      let validacao = await this.getValidateToken();

      if(validacao.resultado) {
        this.$router.push({
          name: "home"
        });
      }
    } else if(!this.$route.params.idEmitenteML) {
      this.$router.push({
        name: "login"
      });
    } else {
      this.idEmitenteML = this.$route.params.idEmitenteML;
      this.emitente.idEmitenteML = this.$route.params.idEmitenteML;

      if(this.$route.params.idProjeto) {
        this.atualizarIdEmitenteMLAcessar(this.$route.params.idEmitenteML);
        this.atualizarIdProjetoAcessar(this.$route.params.idProjeto);
      }
    }
  },
  beforeUnmount() {
    this.encerrarContadorReenviar();
  },
  methods: {
    ...mapActions([
      "getUserRegistered", 
      "enviarCodigoConfirmacao", 
      "verificarCodigoConfirmacao", 
      "registrarConta", 
      "salvarEmitente", 
      "atualizarUsuarioRealizarLogin", 
      "atualizarSenhaRealizarLogin", 
      "atualizarIdEmitenteMLAcessar", 
      "atualizarIdProjetoAcessar", 
      "getValidateToken"
    ]), 
    voltar() {
      this.$router.push({
        name: "login"
      });
    },
    validarDados() {
      let msg = "";

      if(!this.emitente.nome) {
        msg = "O nome completo é um campo obrigatório.";
      }

      if((!this.emitente.cpf) && (!msg)) {
        msg = "O CPF é um campo obrigatório.";
      }

      if((!this.emitente.dataNascimento) && (!msg)) {
        msg = "A data de nascimento é um campo obrigatório.";
      }

      if((!this.emitente.email) && (!msg)) {
        msg = "O e-mail é um campo obrigatório.";
      }

      if((!this.emitente.celular) && (!msg)) {
        msg = "O celular é um campo obrigatório.";
      }

      if((this.emitente.sexo == "0") && (!msg)) {
        msg = "O sexo é um campo obrigatório.";
      }

      if((!this.emitente.cep) && (!msg)) {
        msg = "O CEP é um campo obrigatório.";
      }

      if((!this.emitente.logradouro) && (!msg)) {
        msg = "O logradouro é um campo obrigatório.";
      }

      if((!this.emitente.logradouroNumero) && (!msg)) {
        msg = "O logradouro número é um campo obrigatório.";
      }

      if((!this.emitente.bairro) && (!msg)) {
        msg = "O bairro é um campo obrigatório.";
      }

      if((!this.emitente.cidade) && (!msg)) {
        msg = "A cidade é um campo obrigatório.";
      }

      if((this.emitente.estado == "0") && (!msg)) {
        msg = "O estado é um campo obrigatório.";
      }

      if((this.$refs.qualidadeSenha.obterQualidade() != 4) && (!msg)) {
        msg = "A senha deve conter no mínimo 8 caracteres, sendo pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial.";
      }

      if((this.emitente.senha != this.emitente.senhaRepeticao) && (!msg)) {
        msg = "A senha não confere com a repetição da senha.";
      }

      if((this.emitente.aceiteTermos == "0") && (!msg)) {
        msg = "É necessário aceitar os termos.";        
      }

      if((!this.$refs.recaptchaCadastrese.getResponse()) && (!msg)) {
        msg = "É necessário confirmar que você não é um robô.";
      } else {
        this.emitente.recaptcha = this.$refs.recaptchaCadastrese.getResponse();
      }

      return msg;
    },
    async salvar() {
      this.confirmarEmailCelular = false;

      let msg = this.validarDados();

      if(!msg) {
        this.emitente.codigoConfirmacao = this.codigoConfirmacao;
        this.emitente.codigoConfirmacaoTipo = this.opcaoEnviada.value;

        this.isLoading = true;
        let resultado = await this.registrarConta(this.emitente);
        this.isLoading = false;

        if(resultado.resultado) {
          this.atualizarUsuarioRealizarLogin(this.emitente.email);
          this.atualizarSenhaRealizarLogin(this.emitente.senha);

          this.$refs.alertBox.show(
            "success", 
            "Sucesso", 
            "", 
            "", 
            "Sua conta foi registrada com sucesso!"
          );
        } else if(resultado.msg) {
          Swal.fire({
            icon: "error", 
            title: "Ocorreu um problema", 
            text: resultado.msg, 
            timer: 3000, 
            timerProgressBar: true
          });
        } else {
          Swal.fire({
            icon: "error", 
            title: "Ocorreu um problema", 
            text: "Ocorreu um erro desconhecido. Tente novamente mais tarde.", 
            timer: 3000, 
            timerProgressBar: true
          });
        }
      } else {
        Swal.fire({
          icon: "error", 
          title: "Erro", 
          text: msg, 
          timer: 3000, 
          timerProgressBar: true
        });
      }
    },
    dismissAlert() {
      if(!this.confirmarEmailCelular) {
        this.voltar();
      }
    },
    buscaCEP(dados) {
      if(dados.bairro) {
        this.emitente.bairro = dados.bairro;
      }
      if(dados.complemento) {
        this.emitente.logradouroComplemento = dados.complemento;
      }
      if(dados.localidade) {
        this.emitente.cidade = dados.localidade;
      }
      if(dados.logradouro) {
        this.emitente.logradouro = dados.logradouro;
      }
      if(dados.uf) {
        this.emitente.estado = dados.uf;
      }
      this.emitente.logradouroNumero = "";
    },
    async buscarCadastro() {
      if(this.emitente.cpf) {
        this.isLoading = true;

        let resultado = await this.getUserRegistered({
          CPF: this.emitente.cpf, 
          ID_EMITENTE_ML: this.idEmitenteML
        });

        this.isLoading = false;

        if(resultado.registrado) {
          this.$refs.alertBox.show(
            "success", 
            "Localizado", 
            "", 
            "", 
            "Seu cadastro já consta na plataforma, realize o login. Caso não lembre sua senha de acesso, use a opção esqueci a senha para recuperá-la."
          );
        } else if(resultado.registradoEmitente) {
          let opcoes = [];

          if(resultado.opcoesEnvio.EMAIL) {
            opcoes.push({
              value: "EMAIL", 
              alvo: resultado.opcoesEnvio.EMAIL, 
              title: "Via e-mail", 
              description: "Receber o código de confirmação pelo e-mail: " + resultado.opcoesEnvio.EMAIL
            });
          }

          if(resultado.opcoesEnvio.CELULAR) {
            opcoes.push({
              value: "CELULAR", 
              alvo: resultado.opcoesEnvio.CELULAR, 
              title: "Via SMS", 
              description: "Receber o código de confirmação via SMS no número: " + resultado.opcoesEnvio.CELULAR
            });
          }

          this.$refs.panelTopDown.exibirOpcoes(opcoes);
        } else {
          this.$refs.alertBox.show(
            "error", 
            "Não Localizado", 
            "", 
            "", 
            "Você ainda não está associado. Por favor entre em contato com a empresa para realizar a associação. Em breve você poderá se associar diretamente pela plataforma."
          );
        }
      } else {
        this.$refs.alertBox.show(
          "error", 
          "Erro", 
          "", 
          "", 
          "CPF não informado."
        );
      }
    },
    popularEmitente(resultado) {
      if(resultado.EMITENTE) {
        if(resultado.EMITENTE.RAZAO_SOCIAL) {
          this.emitente.nome = resultado.EMITENTE.RAZAO_SOCIAL;
        }

        if(resultado.NOME_APRESENTACAO) {
          this.emitente.nome_apresentacao = resultado.NOME_APRESENTACAO;
        }

        if(resultado.EMITENTE.DATA_NASCIMENTO) {
          this.emitente.dataNascimento = resultado.EMITENTE.DATA_NASCIMENTO;
        }

        if(resultado.EMITENTE.E_MAIL) {
          this.emitente.email = resultado.EMITENTE.E_MAIL;
        }

        if(resultado.EMITENTE.SEXO) {
          this.emitente.sexo = resultado.EMITENTE.SEXO;
        }

        if(resultado.EMITENTE.CELULAR) {
          this.emitente.celular = resultado.EMITENTE.CELULAR;
        }

        if(resultado.EMITENTE.CEP) {
          this.emitente.cep = resultado.EMITENTE.CEP;
        }

        if(resultado.EMITENTE.LOGRADOURO_NOME) {
          this.emitente.logradouro = "";

          if(resultado.EMITENTE.LOGRADOURO_TIPO) {
            this.emitente.logradouro = resultado.EMITENTE.LOGRADOURO_TIPO + " ";
          }

          this.emitente.logradouro += resultado.EMITENTE.LOGRADOURO_NOME;
        }

        if(resultado.EMITENTE.LOGRADOURO_NUMERO) {
          this.emitente.logradouroNumero = resultado.EMITENTE.LOGRADOURO_NUMERO;
        }

        if(resultado.EMITENTE.LOGRADOURO_COMPLEMENTO) {
          this.emitente.logradouroComplemento = resultado.EMITENTE.LOGRADOURO_COMPLEMENTO;
        }

        if(resultado.EMITENTE.BAIRRO) {
          this.emitente.bairro = resultado.EMITENTE.BAIRRO;
        }

        if(resultado.EMITENTE.CIDADE) {
          this.emitente.cidade = resultado.EMITENTE.CIDADE;
        }

        if(resultado.EMITENTE.ESTADO) {
          this.emitente.estado = resultado.EMITENTE.ESTADO;
        }

        if(resultado.EMITENTE.FACEBOOK) {
          this.emitente.facebook = resultado.EMITENTE.FACEBOOK;
        }

        if(resultado.EMITENTE.INSTAGRAM) {
          this.emitente.instagram = resultado.EMITENTE.INSTAGRAM;
        }

        if(resultado.EMITENTE.X) {
          this.emitente.x = resultado.EMITENTE.X;
        }

        if(resultado.EMITENTE.LINKEDIN) {
          this.emitente.linkedin = resultado.EMITENTE.LINKEDIN;
        }

        if(resultado.EMITENTE.LATTES) {
          this.emitente.lattes = resultado.EMITENTE.LATTES;
        } 

      }
    }, 
    iniciarContadorReenviar() {
      this.timerContadorReenviar = setInterval(() => {
        if(this.contadorReenviar > 0) {
          this.contadorReenviar--;
        }
      }, 1000);
    }, 
    encerrarContadorReenviar() {
      if(this.timerContadorReenviar) {
        clearInterval(this.timerContadorReenviar);
        this.timerContadorReenviar = null;
      }
    },
    async enviarConfirmacao(opcao) {
      this.confirmarEmailCelular = true;
      this.etapaBuscarCPF = false;
      this.contadorReenviar = 60;
      this.opcaoEnviada = opcao;

      this.$refs.panelTopDown.close();

      this.isLoading = true;

      this.enviarCodigoConfirmacao({
        ID_EMITENTE_ML: this.idEmitenteML, 
        TIPO: opcao.value, 
        CPF: this.emitente.cpf
      });

      this.isLoading = false;

      this.$nextTick(() => {
        this.$refs.codigoConfirmacao1.focus();
      });
    },
    focusInput(event, codigo) {
      if(!isNaN(event.key)) {
        if(codigo == 1) {
          this.$refs.codigoConfirmacao1.focus();
        } else if(codigo == 2) {
          this.$refs.codigoConfirmacao2.focus();
        } else if(codigo == 3) {
          this.$refs.codigoConfirmacao3.focus();
        } else if(codigo == 4) {
          this.$refs.codigoConfirmacao4.focus();
        } else if(codigo == 5) {
          this.$refs.codigoConfirmacao5.focus();
        } else if(codigo == 6) {
          this.$refs.codigoConfirmacao6.focus();
        } else if(codigo == 7) {
          this.verificarCodigoConfirmacaoLocal();
        }
      } else if(event.key == "Backspace") {
        if(codigo == 3) {
          this.$refs.codigoConfirmacao1.focus();
        } else if(codigo == 4) {
          this.$refs.codigoConfirmacao2.focus();
        } else if(codigo == 5) {
          this.$refs.codigoConfirmacao3.focus();
        } else if(codigo == 6) {
          this.$refs.codigoConfirmacao4.focus();
        } else if(codigo == 7) {
          this.$refs.codigoConfirmacao5.focus();
        }
      }
    },
    reenviarCodigoConfirmacao() {
      this.confirmarEmailCelular = false;
      this.etapaBuscarCPF = true;

      this.buscarCadastro();
    },
    async verificarCodigoConfirmacaoLocal() {
      this.codigoConfirmacao = this.codigoConfirmacao1 + 
        this.codigoConfirmacao2 + 
        this.codigoConfirmacao3 + 
        this.codigoConfirmacao4 + 
        this.codigoConfirmacao5 + 
        this.codigoConfirmacao6;

      if(this.codigoConfirmacao.length == 6) {
        this.isLoading = true;

        let resultado = await this.verificarCodigoConfirmacao({
          ID_EMITENTE_ML: this.idEmitenteML, 
          CPF: this.emitente.cpf, 
          CODIGO_CONFIRMACAO: this.codigoConfirmacao, 
          TIPO: this.opcaoEnviada.value
        });

        this.isLoading = false;
        
        if(resultado.resultado) {
          this.confirmarEmailCelular = false;
          this.etapaBuscarCPF = false;

          this.popularEmitente(resultado);
        } else {
          Swal.fire({
            icon: "error", 
            title: "Erro", 
            text: "Código de confirmação inválido.", 
            timer: 3000, 
            timerProgressBar: true
          });
        }
      }
    }, 
    verificaBuscaCPF() {
      if(this.etapaBuscarCPF) {
        this.$nextTick(() => {
          if(this.emitente.cpf.length == 14) {
            this.buscarCadastro();
          }
        });
      }
    }, 
    pasteCodigoConfirmacao(event) {
      if((event) && (event.clipboardData) && (event.clipboardData.getData) && (event.clipboardData.getData("text/plain"))) {
        let codigo = event.clipboardData.getData("text/plain");
        codigo = codigo.replace(/[^0-9]/g, "");

        if(codigo.length == 6) {
          let codigos = codigo.split("");
          this.codigoConfirmacao1 = codigos[0];
          this.codigoConfirmacao2 = codigos[1];
          this.codigoConfirmacao3 = codigos[2];
          this.codigoConfirmacao4 = codigos[3];
          this.codigoConfirmacao5 = codigos[4];
          this.codigoConfirmacao6 = codigos[5];

          this.$nextTick(() => {
            this.verificarCodigoConfirmacaoLocal();
          });
        }
      }
    }
  }
}
</script>

<style scoped>

</style>