<template>
  <div class="tf-panel down" :class="{'panel-open': visible}">
    <div class="panel_overlay"></div>
    <div class="panel-box panel-down">
      <div class="header">
        <div class="tf-container">
          <div class="tf-statusbar d-flex justify-content-center align-items-center">
            <h3>Cadastre seu PIN</h3>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <div class="tf-container">
          <div class="tf-form-verify">
            <div class="d-flex group-input-verify">
              <input 
                ref="codigo1" 
                type="tel" 
                maxlength="1" 
                pattern="[0-9]" 
                class="input-verify" 
                v-model="codigo1" 
                @input="codigo1.length == 1 ? $refs.codigo2.focus() : null"
              />
              <input 
                ref="codigo2" 
                type="tel" 
                maxlength="1" 
                pattern="[0-9]" 
                class="input-verify" 
                v-model="codigo2" 
                @input="codigo2.length == 1 ? $refs.codigo3.focus() : null"
              />
              <input 
                ref="codigo3" 
                type="tel" 
                maxlength="1" 
                pattern="[0-9]" 
                class="input-verify" 
                v-model="codigo3" 
                @input="codigo3.length == 1 ? $refs.codigo4.focus() : null"
              />
              <input 
                ref="codigo4" 
                type="tel" 
                maxlength="1" 
                pattern="[0-9]" 
                class="input-verify" 
                v-model="codigo4" 
                @input="codigo4.length == 1 ? cadastrarCodigo() : null"
              />
            </div>
            <div class="text-send-code">
              <p class="fw_4">Esse código PIN de 4 dígitos será solicitado antes de cada pagamento.</p>
            </div>
            <div class="mt-7 mb-6">
              <button type="button" class="tf-btn accent" @click="cadastrarCodigo" :disabled="isLoading">
                {{ isLoading ? 'Aguarde por favor...' : 'Continuar' }} <LoadingIcon v-if="isLoading" size="38" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from 'vuex';
import LoadingIcon from "@/components/LoadingIcon.vue";

export default {
  name: "PanelTopDownPINCadastro", 
  components: {
    LoadingIcon
  },
  data() {
    return {
      isLoading: false,
      visible: false, 
      codigo1: "", 
      codigo2: "", 
      codigo3: "", 
      codigo4: ""
    };
  }, 
  async mounted() {
/*    let temPIN = await this.temPIN();

    if(!temPIN.RESULTADO) {
      this.exibir();
    } */
  },
  methods: {
    ...mapActions([
      "cadastrarPIN",
      "temPIN"
    ]),
    exibir() {
      this.visible = true;

      setTimeout(() => {
        this.$refs.codigo1.focus();
      }, 1000);
    },
    close() {
      if(!this.isLoading) {
        this.visible = false;
        this.limparCodigo();
      }
    },
    limparCodigo() {
      this.codigo1 = "";
      this.codigo2 = "";
      this.codigo3 = "";
      this.codigo4 = "";
    },
    async cadastrarCodigo() {
      if((this.codigo1 + this.codigo2 + this.codigo3 + this.codigo4).length == 4) {
        this.isLoading = true;
        let resultado = await this.cadastrarPIN(this.codigo1 + this.codigo2 + this.codigo3 + this.codigo4);
        this.isLoading = false;

        if(resultado.RESULTADO) {
          Swal.fire({
            icon: "success", 
            title: "Sucesso", 
            text: "PIN cadastrado com sucesso!", 
            showConfirmButton: false, 
            timer: 3000
          });

          this.close();
        } else {
          await Swal.fire({
            icon: "error", 
            title: "Erro", 
            text: resultado.MSG, 
            showConfirmButton: false, 
            timer: 3000
          });

          this.limparCodigo();

          setTimeout(() => {
            this.$refs.codigo1.focus();
          }, 1000);
        }
      } else {
        await Swal.fire({
          icon: "error", 
          title: "Erro", 
          text: "O PIN deve conter 4 dígitos.", 
          showConfirmButton: false, 
          timer: 3000
        });

        this.limparCodigo();

        setTimeout(() => {
          this.$refs.codigo1.focus();
        }, 1000);
      }
    }
  }
}
</script>

<style scoped>

</style>