<template>
  <div class="group-cb mt-5">
    <input 
      type="checkbox" 
      v-model="valor" 
      class="tf-checkbox" 
      :disabled="disabled" 
      true-value="1" 
      false-value="0"
      style="border: 1px solid;"
    />
    <label class="fw_3" @click="check" style="font-size: 15px">
      {{ label }}
    </label> 
  </div>
</template>

<script>
export default {
  name: "InputCheckbox",
  props: [
    "modelValue", 
    "label", 
    "value", 
    "disabled"
  ],
  computed: {
    valor: {
      get: function() {
        return (this.modelValue ? this.modelValue : this.value);
      },
      set: function(newValue) {
        this.$emit("update:modelValue", newValue);
      }
    }
  },
  methods: {
    check() {
      this.$nextTick(() => {
        if(this.valor == "1") {
          this.valor = "0";
        } else {
          this.valor = "1";
        }
      });
    }
  }
}
</script>

<style>

</style>