<template>
  <div class="tf-panel card-popup" :class="{'panel-open': visible}">
    <div class="panel_overlay" @click="close"></div>
    <div class="panel-box panel-down">
      <div class="header">
        <div class="tf-container">
          <div class="tf-statusbar d-flex justify-content-center align-items-center">
            <a href="javascript:;" class="clear-panel" @click="close">
              <i class="icon-left"></i>
            </a>
            <h3>Gerenciar Seus Cartões</h3>
          </div>
        </div>
      </div>
      <div class="content-card mt-3 mb-5">
        <div class="tf-container">
          <div v-for="cartao in filtrarCartoes(cartoes, true)" :key="cartao.ID_ZOOP_EMITENTE_CARTAO" class="tf-card-list bg_surface_color large out-line">
            <div class="logo" style="text-align: center;">
              <img v-if="cartao.BANDEIRA == 'MasterCard'" src="images/logo-banks/mastercard.png" alt="image" style="height: 100%; width: initial;" />
              <img v-if="cartao.BANDEIRA == 'Visa'" src="images/logo-banks/visa.png" alt="image" style="height: 100%; width: initial;" />
              <img v-if="cartao.BANDEIRA == 'Amex'" src="images/logo-banks/amex.png" alt="image" style="height: 100%; width: initial;" />
              <img v-if="(cartao.BANDEIRA !== 'MasterCard') && (cartao.BANDEIRA !== 'Visa') && (cartao.BANDEIRA !== 'Amex')" src="images/logo-banks/creditcard.png" alt="image" style="height: 100%; width: initial;" />
            </div>
            <div class="info">
              <h4 class="fw_6">
                <a href="javascript:;">
                  {{ cartao.BANDEIRA }}
                </a>
                <span v-if="cartao.PRINCIPAL == '1'" class="status status_success">
                  Principal
                </span>
              </h4>
              <p style="line-height: 18px;">
                {{ formataNumeroCartao(cartao.NUMERO_CARTAO) }}
              </p>
              <p style="line-height: 18px;">
                {{ cartao.MES_EXPIRACAO }}/{{ cartao.ANO_EXPIRACAO }}
              </p>
            </div>
          </div>
          <p v-if="filtrarCartoes(cartoes).length" class="auth-line">Escolher outro cartão como principal</p>
          <ul class="box-card">
            <li class="tf-card-list medium" :class="{'bt-line': (index + 1) < filtrarCartoes(cartoes).length}" v-for="(cartao, index) in filtrarCartoes(cartoes)" :key="cartao.ID_ZOOP_EMITENTE_CARTAO">
              <div class="logo" style="text-align: center;">
                <img v-if="cartao.BANDEIRA == 'MasterCard'" src="images/logo-banks/mastercard.png" alt="image" style="height: 100%; width: initial;" />
                <img v-if="cartao.BANDEIRA == 'Visa'" src="images/logo-banks/visa.png" alt="image" style="height: 100%; width: initial;" />
                <img v-if="cartao.BANDEIRA == 'Amex'" src="images/logo-banks/amex.png" alt="image" style="height: 100%; width: initial;" />
                <img v-if="(cartao.BANDEIRA !== 'MasterCard') && (cartao.BANDEIRA !== 'Visa') && (cartao.BANDEIRA !== 'Amex')" src="images/logo-banks/creditcard.png" alt="image" style="height: 100%; width: initial;" />
              </div>
              <div class="info">
                <h4 class="fw_6">
                  <a href="javascript:;">{{ cartao.BANDEIRA }}</a>
                  <span v-if="cartao.PRINCIPAL == '1'" class="status status_success">Principal</span>
                </h4>
                <p style="line-height: 18px;">
                  {{ formataNumeroCartao(cartao.NUMERO_CARTAO) }}
                </p>
                <p style="line-height: 18px;">
                  {{ cartao.MES_EXPIRACAO }}/{{ cartao.ANO_EXPIRACAO }}
                </p>
              </div>
              <button type="button" class="tf-btn accent large btn-selecionar-principal" @click="selecionarPrincipalLocal(cartao)" :disabled="isLoading">
                {{ isLoading ? "Aguarde..." : "Tornar Principal" }}
              </button>
            </li>
          </ul>
          <div class="tf-spacing-20"></div>
          <a href="javascript:;" class="tf-btn large" @click="adicionarCartao">
            Adicionar novo cartão <i class="icon-plus fw_7"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";

export default {
  name: "PanelTopDownCartoes", 
  data() {
    return {
      isLoading: false,
      opcoes: [], 
      visible: false, 
      cartoes: []
    };
  },
  props: ["title"], 
  async mounted() {
    await this.buscarCartoes();
  },
  methods: {
    ...mapActions([
      "listarCartoes", 
      "selecionarPrincipal"
    ]), 
    exibirOpcoes(/*opcoes*/) {
      this.visible = true;
      // this.opcoes = opcoes;
    },
    close() {
      this.visible = false;
    },
    filtrarCartoes(cartoes, principal = false) {
      if(cartoes) {
        return cartoes.filter(cartao => {
          if(principal) {
            return cartao.PRINCIPAL == 1;
          } else {
            return (!cartao.PRINCIPAL) || (cartao.PRINCIPAL == 0);
          }
        });
      } else {
        return [];
      }
    },
    async buscarCartoes() {
      this.cartoes = await this.listarCartoes();
    },
    adicionarCartao() {
      this.close();
      this.$router.push({
        name: "adicionarCartao"
      });
    },
    formataNumeroCartao(numero) {
      return "**** **** **** " + numero.replace(/[\D]/g, '');
    }, 
    async selecionarPrincipalLocal(cartao) {
      this.isLoading = true;
      await this.selecionarPrincipal(cartao.ID_ZOOP_EMITENTE_CARTAO);
      await this.buscarCartoes();
      this.isLoading = false;

      Swal.fire({
        icon: "success", 
        title: "Sucesso", 
        text: "Cartão principal alterado com sucesso.", 
        timer: 3000, 
        timerProgressBar: true
      });
    }
  }
}
</script>

<style scoped>
  .status {
    display: inline-block;
    font-size: 10px;
    color: white;
    border-radius: 10px;
    padding-left: 4px;
    padding-right: 4px;
    line-height: 15px;
    vertical-align: middle;
    margin-left: 6px;
    margin-bottom: 3px;
  }
  
  .status_success {
    background-color: #44b461;
  }

  .btn-selecionar-principal {
    width: 90px;
    border: 0px solid;
    font-size: 14px;
    line-height: 16px;
    padding: 8px;
    text-align: center;
    cursor: pointer;
  }
</style>