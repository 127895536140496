<template>
  <div class="group-input">
    <label>{{ label ? label: 'CNPJ' }}</label>
    <input 
      type="text" 
      v-model="valor" 
      :placeholder="placeholder" 
      v-maska 
      data-maska="##.###.###/####-##" 
      :disabled="disabled" 
      @blur="validarCNPJ"
    >
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "InputCNPJ", 
  data() {
    return {
      valid: null
    };
  },
  props: [
    "modelValue", 
    "label", 
    "placeholder", 
    "value", 
    "disabled"
  ],
  computed: {
    valor: {
      get: function() {
        return (this.modelValue ? this.modelValue : this.value);
      },
      set: function(newValue) {
        this.$emit("update:modelValue", newValue);
      }
    }
  },
  methods: {
    mascaraCNPJ(valor) {
      let cpf = valor.replace(/[^\d]+/g, "");
      return cpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    },
    validarCNPJ(mostraMensagem = true) {
      if(this.valor) {
        let cnpj = "";

        if(this.valor) {
          cnpj = this.valor.replace(/[^\d]+/g, "");
        }

        this.valid = null;

        if (cnpj == "") this.valid = false;
        
        if (cnpj.length != 14) this.valid = false;
 
        if (cnpj == "00000000000000" || 
            cnpj == "11111111111111" || 
            cnpj == "22222222222222" || 
            cnpj == "33333333333333" || 
            cnpj == "44444444444444" || 
            cnpj == "55555555555555" || 
            cnpj == "66666666666666" || 
            cnpj == "77777777777777" || 
            cnpj == "88888888888888" || 
            cnpj == "99999999999999") {
          this.valid = false;
        }
            
        let tamanho = cnpj.length - 2;
        let numeros = cnpj.substring(0,tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0)) this.valid = false;
            
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--;
          if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1)) this.valid = false;

        if (this.valid == null) this.valid = true;

        if(!this.valid) {
          this.valor = "";

          if(mostraMensagem) {
            Swal.fire({
              icon: "error", 
              title: "Oops...", 
              text: "O CNPJ informado é inválido.", 
              timer: 3000, 
              timerProgressBar: true
            });
          }
        }
      }
    },
    retornarValido() {
      return this.valid;
    }
  }
}
</script>

<style scoped>

</style>