<template>
  <HeaderComponent 
    titulo="Histórico" 
    @voltar="voltar"
  />
  <div id="app-wrap">
    <div class="app-section st1 mt-1 bg_white_color">
      <div class="tf-container">
        <div class="wrap-total">
          <div class="total-item">
            <a href="#" class="box-icon bg_primary"><i class="icon-arrow-up_minor primary_color"></i></a>
            <div class="content">
              <p class="fw_4">Pagos</p>
              <h2 class="fw_6 success_color">{{ retornaValorTotalPago() }}</h2>
            </div>
          </div>
          <div class="total-item">
            <a href="#" class="box-icon bg_critical"><i class="icon-arrow-up_minor critical_color arrow-down"></i></a>
            <div class="content">
              <p class="fw_4">Pendentes</p>
              <h2 class="fw_6 critical_color">{{ retornaValorTotalPendente() }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-section st1 mt-1 bg_white_color">
      <div class="tf-container">
        <div class="tf-tab">
          <ul class="menu-tabs timeline mb-5">
            <li class="nav-tab" :class="{'active': periodo == 30}" style="cursor: pointer" @click="modificarPeriodo(30)">
              {{ periodo == 30 ? 'Últimos' : '' }} 30 dias {{ periodo == 30 ? 'até hoje' : '' }}
            </li>
            <li class="nav-tab" :class="{'active': periodo == 60}" style="cursor: pointer" @click="modificarPeriodo(60)">
              {{ periodo == 60 ? 'Últimos' : '' }} 60 dias {{ periodo == 60 ? 'até hoje' : '' }}
            </li>
            <li class="nav-tab" :class="{'active': periodo == 90}" style="cursor: pointer" @click="modificarPeriodo(90)">
              {{ periodo == 90 ? 'Últimos' : '' }} 90 dias {{ periodo == 90 ? 'até hoje' : '' }}
            </li>
            <li class="nav-tab" :class="{'active': periodo == 180}" style="cursor: pointer" @click="modificarPeriodo(180)">
              {{ periodo == 180 ? 'Últimos' : '' }} 180 dias {{ periodo == 180 ? 'até hoje' : '' }}
            </li>
            <li class="nav-tab" :class="{'active': periodo == 360}" style="cursor: pointer" @click="modificarPeriodo(360)">
              {{ periodo == 360 ? 'Últimos' : '' }} 360 dias {{ periodo == 360 ? 'até hoje' : '' }}
            </li>
            <!--<li class="nav-tab" :class="{'active': periodo == 0}" style="cursor: pointer" @click="modificarPeriodo(0)">
              Customizado
            </li>-->
          </ul> 
        </div>
      </div>
    </div>
    <div class="app-section st1 mt-1 mb-5 bg_white_color">
      <div class="tf-container" v-if="isLoading" style="text-align: center;">
        <LoadingIcon size="150" />
      </div>
      <div class="tf-container" v-if="!isLoading">
        <div class="trading-month" v-for="fluxo in fluxos" :key="fluxo.MES + fluxo.ANO">
          <h4 class="fw_5 mb-3">
            {{ tituloFluxoMes(fluxo) }}
          </h4>
          <div class="group-trading-history mb-5">
            <a class="tf-trading-history" href="javascript:;" v-for="fluxoItem in fluxo.FLUXOS" :key="fluxoItem.ID_FLUXO_DE_CAIXA" @click="acessarPagamento(fluxoItem)">
              <div class="inner-left">
                <div class="icon-box rgba_primary">
                  <img :src="'https://backend.micrologos.com.br/auth/logomarcaCliente/' + fluxoItem.ID_EMITENTE_ML" />
                </div>
                <div class="content">
                  <h4>
                    {{ (fluxoItem.PROJETO ? fluxoItem.PROJETO : (fluxoItem.HISTORICO ? fluxoItem.HISTORICO : 'Sem Descrição')) }}
                    <span class="status" :class="{'status_success': fluxoItem.DATA_PAGAMENTO, 'status_critical': !fluxoItem.DATA_PAGAMENTO}">
                      {{ fluxoItem.DATA_PAGAMENTO ? 'Pago' : 'Pendente' }}
                    </span>
                  </h4>
                  <p>Vencimento: {{ retornaDataFormatada(fluxoItem.DATA_VENCIMENTO) }}</p>
                  <p>{{ fluxoItem.EMITENTE_ML }}</p>
                </div>
              </div>
              <span class="num-val" :class="{'success_color': fluxoItem.DATA_PAGAMENTO, 'critical_color': !fluxoItem.DATA_PAGAMENTO}">
                {{ formataValorMonetario(fluxoItem.VALOR) }} <i class="icon-right" style="color: #1e1e1e;"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MenuInferior />
</template>

<script>
import { mapActions } from "vuex";

import moment from "moment";

import LoadingIcon from "@/components/LoadingIcon.vue";

import HeaderComponent from "@/components/template/HeaderComponent.vue";
import MenuInferior from "@/components/template/MenuInferior.vue";

export default {
  name: "HistoricoPagamentos",
  components: {
    LoadingIcon, 
    HeaderComponent, 
    MenuInferior
  },
  data() {
    return {
      isLoading: false, 
      periodo: 30, 
      fluxos: []
    };
  },
  mounted() {
    this.buscarHistorico();
  },
  methods: {
    ...mapActions(["listarFluxos", "updatePendenciaPagar"]), 
    voltar() {
      this.$router.push({
        name: "home"
      });
    },
    modificarPeriodo(periodo) {
      this.periodo = periodo;
      
      this.buscarHistorico();
    },
    async buscarHistorico() {
      this.isLoading = true;
      this.fluxos = await this.listarFluxos({
        DATA_INICIAL: moment().subtract(this.periodo, "days").format("YYYY-MM-DD")
      });
      this.isLoading = false;
    },
    tituloFluxoMes(fluxo) {
      return moment(fluxo.ANO + "-" + fluxo.MES + "-01").format("MMMM YYYY").charAt(0).toUpperCase() + moment(fluxo.ANO + "-" + fluxo.MES + "-01").format("MMMM YYYY").slice(1);
    },
    retornaDataFormatada(data) {
      return moment(data, "YYYYMMDD[T]HHmmss").format("DD/MM/YYYY");
    },
    formataValorMonetario(valor) {
      return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    },
    retornaValorTotalPago() {
      let valorTotal = 0;

      this.fluxos.forEach(fluxo => {
        fluxo.FLUXOS.forEach(fluxoItem => {
          if(fluxoItem.DATA_PAGAMENTO) {
            valorTotal += fluxoItem.VALOR;
          }
        });
      });

      return this.formataValorMonetario(valorTotal);
    },
    retornaValorTotalPendente() {
      let valorTotal = 0;

      this.fluxos.forEach(fluxo => {
        fluxo.FLUXOS.forEach(fluxoItem => {
          if(!fluxoItem.DATA_PAGAMENTO) {
            valorTotal += fluxoItem.VALOR;
          }
        });
      });

      return this.formataValorMonetario(valorTotal);
    },
    acessarPagamento(fluxo) {
      this.updatePendenciaPagar(fluxo);

      this.$router.push({
        name: "pendencia"
      });
    }
  }
}
</script>

<style>
  .status {
    display: inline-block;
    font-size: 10px;
    color: white;
    border-radius: 10px;
    padding-left: 4px;
    padding-right: 4px;
    line-height: 15px;
    vertical-align: middle;
    margin-left: 4px;
    margin-bottom: 3px;
  }

  .status_critical {
    background-color: #ea3434;
  }

  .status_success {
    background-color: #44b461;
  }
</style>