<template>
  <div class="card-secton">
    <div class="tf-container">
      <div class="tf-balance-box">
        <div class="balance">
          <div class="row">
            <div class="col-6 br-right">
              <div class="row">
                <!--<div class="col-6 br-right">
                    <div class="inner-left">
                        <p>Saldo:</p>
                        <h3>R$ 0,00</h3>
                    </div>
                </div>
                <div class="col-6 br-left">-->
                <div class="inner-left">
                  <p>Pendências 360 dias até hoje:</p>
                  <h3 v-if="!isLoading">
                    {{ formataValorMonetario(total) }}
                  </h3>
                  <h3 v-if="isLoading">
                    <LoadingIcon size="30" />
                  </h3>
                </div>
                <!--</div>-->
              </div>
            </div>
            <div class="col-6">
              <div class="inner-right">
                <span style="font-size: 14px; padding: 0px; width: 64px; margin: 10px 0px 0px auto; display: block; cursor: pointer;" @click="sair">
                  <img src="/images/logout_icon.png" style="width: 22px;" /> Sair
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="wallet-footer">
          <ul class="d-flex justify-content-between align-items-center">
            <li class="wallet-card-item">
              <a class="fw_6 text-center" href="javascript:;" @click="acessarHistorico">
                <ul class="icon icon-group-transfers">
                  <li class="path1"></li>
                  <li class="path2"></li>
                  <li class="path3"></li>
                </ul>
                Pagar
              </a>
            </li>
            <!--<li class="wallet-card-item">
                <a class="fw_6" href="29_topup.html">
                    <ul class="icon icon-topup">
                        <li class="path1"></li>
                        <li class="path2"></li>
                        <li class="path3"></li>
                        <li class="path4"></li>
                    </ul>
                    Depositar
                </a>
            </li>-->
            <li class="wallet-card-item">
              <a class="fw_6 btn-card-popup" href="javascript:;" @click="$emit('acessarCartoes')">
                <ul class="icon icon-group-credit-card">
                  <li class="path1"></li>
                  <li class="path2"></li>
                  <li class="path3"></li>
                </ul>
                Cartões
              </a>
            </li>
            <li class="wallet-card-item">
              <a class="fw_6" href="javascript:;" @click="acessarQRCodeAssociar">
                <ul class="icon icon-my-qr">
                  <li class="path1"></li>
                  <li class="path2"></li>
                  <li class="path3"></li>
                  <li class="path4"></li>
                  <li class="path5"></li>
                  <li class="path6"></li>
                  <li class="path7"></li>
                </ul>
                Associar QR
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import LoadingIcon from "@/components/LoadingIcon.vue";

export default {
  name: "CardPendencia", 
  components: {
    LoadingIcon
  }, 
  data() {
    return {
      isLoading: false, 
      total: 0
    };
  },
  async mounted() {
    this.isLoading = true;
    let pendencia = await this.pendenciaTotal360dias();
    this.total = pendencia.TOTAL;
    this.isLoading = false;
  },
  methods: {
    ...mapActions(["logout", "pendenciaTotal360dias"]),
    acessarQRCodeAssociar() {
      this.$router.push({
        name: "qrcodeAssociar"
      });
    },
    formataValorMonetario(valor) {
      if(valor) {
        return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
      } else {
        return "";
      }
    },
    acessarHistorico() {
      this.$router.push({
        name: "historico"
      });
    },
    sair() {
      this.logout();

      this.$router.push({
        name: "login"
      });
    }
  }
}
</script>

<style>

</style>