<template>
  <div 
    v-if="visible" 
    class="wrap-success" 
    style="position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; z-index: 1000;"
  >
		<div class="success_box" style="margin: 0px 32px 0px 16px;">
			<div class="icon-1 ani3">
				<span 
          class="circle-box lg bg-circle check-icon" 
          :class="{'red': tipo == 'error'}"
        >
          <img 
            v-if="tipo == 'error'" 
            src="/images/circle_xmark_icon.png"
          />
        </span>
			</div>
			<div class="icon-2 ani5">
				<span 
          class="circle-box md bg-circle" 
          :class="{'red': tipo == 'error'}"
        ></span>
			</div>
			<div class="icon-3 ani8">
				<span 
          class="circle-box md bg-circle" 
          :class="{'red': tipo == 'error'}"
        ></span>
			</div>
			<div class="icon-4 ani2">
				<span 
          class="circle-box sm bg-circle" 
          :class="{'red': tipo == 'error'}"
        ></span>
			</div>
			<div class="content">
				<div class="top">
					<h2>
            {{ titulo }}
          </h2>
					<p class="fw_4">
            {{ subtitulo }}
          </p>
				</div>
				<div class="tf-spacing-16"></div>
				<div class="inner">
          <slot></slot>
				</div>
				<div class="tf-spacing-16"></div>
				<div class="bottom">
					<p class="on_surface_color fw_6">
            {{ mensagemTitulo }}
          </p>
					<p>
            {{ mensagem }}
          </p>
				</div>
			</div>
			<a href="javascript:;" class="tf-btn accent large" @click="dismissAlert">
        Ok
      </a>
		</div>
		<span class="line-through through-1"></span>
		<span class="line-through through-2"></span>
		<span class="line-through through-3"></span>
		<span class="line-through through-4"></span>
		<span class="line-through through-5"></span>
		<span class="line-through through-6"></span>
  </div>
</template>

<script>
export default {
  name: "AlertBox",
  data() {
    return {
      visible: false,
      tipo: "success",
      titulo: "",
      subtitulo: "",
      mensagemTitulo: "",
      mensagem: ""
    };
  },
  methods: {
    show(tipo, titulo, subtitulo = "", mensagemTitulo = "", mensagem = "") {
      this.visible = true;

      this.tipo = tipo;
      this.titulo = titulo;
      this.subtitulo = subtitulo;
      this.mensagemTitulo = mensagemTitulo;
      this.mensagem = mensagem;
    },
    dismissAlert() {
      this.visible = false;
      this.$emit("dismiss");
    }
  }
}
</script>

<style scoped>
  .circle-box.bg-circle.red {
    background-color: #b44444;
  }

  .circle-box.check-icon.red::after {
    display: none;
  }
</style>