import ApiService from "@/store/api.service";
import JwtService from "@/store/jwt.service";

const state = {
  email: "", 
  celular: "", 
  cpfCnpj: "", 
  nome: "", 
  nomeApresentacao: "", 
  foto: "", 
  usuarioRealizarLogin: "", 
  senhaRealizarLogin: "", 
  idEmitenteMLAcessar: "", 
  idProjetoAcessar: "",
  associacoes: []
};

const getters = {
  email(state) {
    return state.email;
  }, 
  celular(state) {
    return state.celular;
  }, 
  cpfCnpj(state) {
    return state.cpfCnpj;
  }, 
  nome(state) {
    return state.nome;
  }, 
  nomeApresentacao(state) {
    return state.nomeApresentacao;
  },             
  foto(state) {
    return state.foto;
  }, 
  usuarioRealizarLogin(state) {
    return state.usuarioRealizarLogin;
  }, 
  senhaRealizarLogin(state) {
    return state.senhaRealizarLogin;
  }, 
  idEmitenteMLAcessar(state) {
    return state.idEmitenteMLAcessar;
  }, 
  idProjetoAcessar(state) {
    return state.idProjetoAcessar;
  },
  associacoes(state) {
    return state.associacoes;
  }
};

const mutations = {
  updateUserInfo(store, userInfo) {
    if(userInfo.email) {
      store.email = userInfo.email;
    }

    if(userInfo.celular) {
      store.celular = userInfo.celular;
    }

    if(userInfo.cpfCnpj) {
      store.cpfCnpj = userInfo.cpfCnpj;
    }

    if(userInfo.nome) {
      store.nome = userInfo.nome;
    }

    if(userInfo.nomeApresentacao) {
      store.nomeApresentacao = userInfo.nomeApresentacao;
    }

    if(userInfo.foto) {
      store.foto = userInfo.foto;
    }
    
    if(userInfo.associacoes) {
      store.associacoes = userInfo.associacoes;
    }

    if(userInfo.token) {
      JwtService.saveToken(userInfo.token);
    }
  }, 
  updateUsuarioRealizarLogin(store, usuarioRealizarLogin) {
    store.usuarioRealizarLogin = usuarioRealizarLogin;
  }, 
  updateSenhaRealizarLogin(store, senhaRealizarLogin) {
    store.senhaRealizarLogin = senhaRealizarLogin;
  }, 
  updateIdEmitenteMLAcessar(store, idEmitenteMLAcessar) {
    store.idEmitenteMLAcessar = idEmitenteMLAcessar;
  }, 
  updateIdProjetoAcessar(store, idProjetoAcessar) {
    store.idProjetoAcessar = idProjetoAcessar;
  }
};

const actions = {
  atualizarUsuarioRealizarLogin({ commit }, usuarioRealizarLogin) {
    commit("updateUsuarioRealizarLogin", usuarioRealizarLogin);
  }, 
  atualizarSenhaRealizarLogin({ commit }, senhaRealizarLogin) {
    commit("updateSenhaRealizarLogin", senhaRealizarLogin);
  }, 
  atualizarIdEmitenteMLAcessar({ commit }, idEmitenteMLAcessar) {
    commit("updateIdEmitenteMLAcessar", idEmitenteMLAcessar);
  }, 
  atualizarIdProjetoAcessar({ commit }, idProjetoAcessar) {
    commit("updateIdProjetoAcessar", idProjetoAcessar);
  }, 
  realizarLogin({ commit }, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("auth/loginPaylogosApp", dados).then(({ data }) => {
        if(data.resultado) {
          commit("updateUserInfo", data);
        }
        resolve(data);
      });
    });
  }, 
  getValidateToken({ commit }) {
    return new Promise(resolve => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("auth/verifyPaylogosApp").then(({ data }) => {
          commit('updateUserInfo', data);
          if (data.token) {
            JwtService.saveToken(data.token);
          }
          resolve(data);
        });
      } else {
        resolve({resultado: false});
      }
    });
  }, 
  getUserRegistered(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("auth/userRegisteredPaylogos", dados).then(({ data }) => {
        resolve(data);
      });
    });
  }, 
  enviarCodigoConfirmacao(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("auth/enviarCodigoConfirmacaoPaylogos", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  verificarCodigoConfirmacao(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("auth/verificarCodigoConfirmacaoPaylogos", dados).then(({ data }) => {
        resolve(data);
      });
    });
  },
  registrarConta(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("auth/registrarContaAppPaylogos", dados).then(({ data }) => {
        resolve(data);
      });
    });
  }, 
  esqueciSenha(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("auth/esqueciSenhaPaylogosApp", dados).then(({ data }) => {
        resolve(data);
      });
    });
  }, 
  checarCodigoRecuperacao(context, dados) {
    return new Promise(resolve => {
      ApiService.setHeader();
      ApiService.post("auth/checarCodigoRecuperacaoPaylogosApp", dados).then(({ data }) => {
        resolve(data);
      });
    });
  }, 
  logout() {
    JwtService.destroyToken();
  }
};

export default {
  state, 
  actions, 
  mutations, 
  getters
};