<template>
  <div>
    <AppLoader v-if="appIsLoading" />
    <router-view/>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import JwtService from "@/store/jwt.service";
import AppLoader from "@/components/AppLoader.vue";

export default {
  name: "App", 
  data() {
    return {
      appIsLoading: true
    };
  },
  components: {
    AppLoader
  },
  async mounted() {
    moment.locale('pt-br');

    if(JwtService.getToken()) {
      let validacao = await this.getValidateToken();

      if(!validacao.resultado) {
        this.logout();

        this.$router.push({
          name: "login"
        });
      }
    }

    this.$nextTick(() => {
      this.appIsLoading = false;
    });
  },
  methods: {
    ...mapActions(["getValidateToken", "logout"])
  }
}
</script>

<style lang="scss">
  .swal2-container {
    z-index: 99999
  }
</style>
