<template>
  <div class="group-input">
    <label>
      {{ label ? label: 'CPF' }} <span v-if="required" style="color: red">*</span>
    </label>
    <input 
      type="text" 
      v-model="valor" 
      :placeholder="placeholder" 
      v-maska 
      data-maska="###.###.###-##" 
      :disabled="disabled" 
      @blur="validarCPF"
      :style="{
        'background': (disabled ? 'rgb(0 0 0 / 7%)' : '')
      }"
    >
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "InputCPF", 
  data() {
    return {
      valid: null
    };
  },
  props: [
    "modelValue", 
    "label", 
    "placeholder", 
    "value", 
    "disabled", 
    "required"
  ],
  computed: {
    valor: {
      get: function() {
        return (this.modelValue ? this.modelValue : this.value);
      },
      set: function(newValue) {
        this.$emit("update:modelValue", newValue);
      }
    }
  },
  methods: {
    mascaraCPF(valor) {
      let cpf = valor.replace(/[^\d]+/g, "");
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },
    validarCPF(mostraMensagem = true) {
      if(this.valor) {
        let cpf = "";

        if(this.valor) {
          cpf = this.valor.replace(/[^\d]+/g, "");
        }

        this.valid = null;

        if (cpf == "") this.valid = false;

        if (
          cpf.length != 11 ||
          cpf == "00000000000" ||
          cpf == "11111111111" ||
          cpf == "22222222222" ||
          cpf == "33333333333" ||
          cpf == "44444444444" ||
          cpf == "55555555555" ||
          cpf == "66666666666" ||
          cpf == "77777777777" ||
          cpf == "88888888888" ||
          cpf == "99999999999"
        )
          this.valid = false;

        let add = 0;
        let i = 0;

        for (i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);

        let rev = 11 - (add % 11);

        if (rev == 10 || rev == 11) rev = 0;

        if (rev != parseInt(cpf.charAt(9))) this.valid = false;

        add = 0;

        for (i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);

        rev = 11 - (add % 11);

        if (rev == 10 || rev == 11) rev = 0;

        if (rev != parseInt(cpf.charAt(10))) this.valid = false;

        if (this.valid == null) this.valid = true;

        if(!this.valid) {
          this.valor = "";

          if(mostraMensagem) {
            Swal.fire({
              icon: "error", 
              title: "Oops...", 
              text: "O CPF informado é inválido.", 
              timer: 3000, 
              timerProgressBar: true
            });
          }
        } else {
          this.$emit("cpfValido", this.valid);
        }
      }
    },
    retornarValido() {
      return this.valid;
    }
  }
}
</script>

<style scoped>

</style>