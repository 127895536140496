<template>
  <div class="mt-5 mb-9">
    <div class="tf-container">
      <div class="tf-title d-flex justify-content-between">
        <h3 class="fw_6">Loja</h3>
        <a href="javascript:;" class="primary_color fw_6 btn-repicient" @click="acessarParceiros">
          Ver Todos
        </a>
      </div>
      <div class="mt-5">
        <div class="swiper-container banner-tes">
          <div class="swiper-wrapper">
            <div v-for="banner in banners" :key="banner.id" class="swiper-slide">
              <img :src="'https://sboportal.org.br'+banner.img" alt="images">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ParceirosLista",
  data() {
    return {
      swiper: null,
      banners: []
    };
  },
  async mounted() {
    let url = "https://sbo.apisowtec.com.br/banners/home";
    const response = await axios.get(url);
    this.banners = response.data;

    this.swiper = new window.Swiper(".banner-tes", {
      speed: 1000,
      parallax: true,
      slidesPerView: 1.2,
      spaceBetween: 16,
      loop: true,
      navigation: {
        clickable: true,
        nextEl: ".button-lo-next",
        prevEl: ".button-lo-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },

      breakpoints: {
        1024: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 3,
        },
        500: {
          slidesPerView: 2,
        },
      },
    });
  },
  beforeUnmount() {
    if(this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
  },
  methods: {
    acessarParceiros() {
      this.$router.push({
        name: "loja"
      });
    }
  }
}
</script>

<style>

</style>