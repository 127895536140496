import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ApiService from "@/store/api.service";
import "animate.css";
import VueConfetti from "vue-confetti";
import { vMaska } from "maska";
import mitt from "mitt";
import JwtService from "@/store/jwt.service";

const emitter = mitt();

window.installPrompt = null;

window.addEventListener("beforeinstallprompt", (event) => {
  event.preventDefault();
  window.installPrompt = event;
  emitter.emit("beforeinstallprompt", event);
});

window.addEventListener("appinstalled", () => {
  window.installPrompt = null;
});

var url = window.location.href;

var token = /token=([^&]+)/.exec(url);
if (token) {
  token = token[1];
}

if (token) {
  JwtService.saveToken(token);
}

const app = createApp(App);
ApiService.init(app);
app.use(store);
app.use(router);
app.directive("maska", vMaska);
app.use(VueConfetti);
app.config.globalProperties.emitter = emitter;
app.mount("#app");
