<template>
  <HeaderComponent 
    titulo="Esqueceu a Senha?" 
    @voltar="voltar"
  />
  <div class="register-section" id="app-wrap">
    <div class="tf-container" style="margin-top: 26px;">
      <div class="tf-form tf-form-verify" v-if="!confirmarCodigo">
        <p class="mb-7 fw-3 text-center">
          Digite seu e-mail ou celular para recuperar sua senha:
        </p>
        <InputText 
          label="E-mail ou Celular" 
          placeholder="E-mail ou Celular" 
          v-model="usuario" 
          :disabled="isLoading"
        />
        <div class="bottom-navigation-bar bottom-btn-fixed">
          <ButtonPadrao 
            label="Enviar" 
            :isLoading="isLoading" 
            @click="enviar"
          />
        </div>
      </div>
      <div class="tf-form tf-form-verify" v-if="(confirmarCodigo) && (!inserirNovaSenha)">
        <p class="mb-7 fw-3 text-center">
          Um código de recuperação foi enviado para o seu e-mail e celular. Digite o código a baixo:
        </p>
        <div class="d-flex group-input-verify">
          <input 
            ref="codigoRecuperacao1" 
            v-model="codigoRecuperacao1" 
            type="tel" 
            maxlength="1" 
            pattern="[0-9]" 
            class="input-verify" 
            @keyup="focusInput($event, 2)"
          />
          <input 
            ref="codigoRecuperacao2" 
            v-model="codigoRecuperacao2" 
            type="tel" 
            maxlength="1" 
            pattern="[0-9]" 
            class="input-verify" 
            @keyup="focusInput($event, 3)"
          />
          <input 
            ref="codigoRecuperacao3" 
            v-model="codigoRecuperacao3" 
            type="tel" 
            maxlength="1" 
            pattern="[0-9]" 
            class="input-verify" 
            @keyup="focusInput($event, 4)"
          />
          <input 
            ref="codigoRecuperacao4" 
            v-model="codigoRecuperacao4" 
            type="tel" 
            maxlength="1" 
            pattern="[0-9]" 
            class="input-verify" 
            @keyup="focusInput($event, 5)"
          />
          <input 
            ref="codigoRecuperacao5" 
            v-model="codigoRecuperacao5" 
            type="tel" 
            maxlength="1" 
            pattern="[0-9]" 
            class="input-verify" 
            @keyup="focusInput($event, 6)"
          />
          <input 
            ref="codigoRecuperacao6" 
            v-model="codigoRecuperacao6" 
            type="tel" 
            maxlength="1" 
            pattern="[0-9]" 
            class="input-verify" 
            @keyup="focusInput($event, 7)"
          />
        </div>
        <div class="text-send-code">
          <p class="fw_4">
            Um código de 6 dígitos foi enviado via SMS e E-mail.
          </p>
          <p v-if="contadorReenviar > 0" class="primary_color fw_7">
            Reenviar em &nbsp;<span class="js-countdown">{{ contadorReenviar }}</span>&nbsp;segundos
          </p>
          <p v-if="contadorReenviar == 0" class="primary_color fw_7">
            <a 
              href="javascript:;" 
              class="auth-link-rg" 
              @click="reenviarCodigoRecuperacao"
            >
              Reenviar um novo código
            </a>
          </p>
        </div>
        <div class="bottom-navigation-bar bottom-btn-fixed">
          <ButtonPadrao 
            label="Continuar" 
            :isLoading="isLoading" 
            @click="verificarCodigoRecuperacao"
          />
        </div>
      </div>
      <div class="tf-form tf-form-verify" v-if="inserirNovaSenha">
        <p class="mb-7 fw-3 text-center">
          Informe uma nova senha:
        </p>
        <InputSenha 
          placeholder="Senha" 
          v-model="senha" 
          style="margin-top: 28px;" 
          :disabled="isLoading"
        />
        <QualidadeSenha 
          ref="qualidadeSenha" 
          :senha="senha" 
          style="margin-top: 8px;"
        />
        <InputSenha 
          label="Repita a Senha" 
          placeholder="Repita a Senha" 
          v-model="senhaRepeticao" 
          style="margin-top: 20px;" 
          :disabled="isLoading"
        />
        <div class="bottom-navigation-bar bottom-btn-fixed">
          <ButtonPadrao 
            label="Enviar" 
            :isLoading="isLoading" 
            @click="enviarNovaSenha"
          />
        </div>
      </div>
    </div>
    <AlertBox ref="alertBox" @dismiss="dismissAlert">
    </AlertBox>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Swal from "sweetalert2";

import HeaderComponent from "@/components/template/HeaderComponent.vue";

import InputText from "@/components/inputs/InputText.vue";
import InputSenha from "@/components/inputs/InputSenha.vue";
import ButtonPadrao from "@/components/buttons/ButtonPadrao.vue";
import QualidadeSenha from "@/components/QualidadeSenha.vue";

import AlertBox from "@/components/AlertBox.vue";

export default {
  name: "EsqueciSenha",
  components: {
    HeaderComponent, 
    InputText, 
    InputSenha, 
    ButtonPadrao, 
    QualidadeSenha, 
    AlertBox
  },
  data() {
    return {
      isLoading: false, 
      usuario: "", 
      confirmarCodigo: false, 
      inserirNovaSenha: false, 
      codigoRecuperacao1: "",
      codigoRecuperacao2: "",
      codigoRecuperacao3: "",
      codigoRecuperacao4: "",
      codigoRecuperacao5: "",
      codigoRecuperacao6: "",
      codigoRecuperacao: "",
      contadorReenviar: 0,
      timerContadorReenviar: null, 
      senha: "", 
      senhaRepeticao: ""
    };
  },
  mounted() {
    this.iniciarContadorReenviar();
  },
  beforeUnmount() {
    if(this.timerContadorReenviar) {
      clearInterval(this.timerContadorReenviar);
      this.timerContadorReenviar = null;
    }
  },
  methods: {
    ...mapActions(["esqueciSenha", "checarCodigoRecuperacao"]), 
    async enviar() {
      if(this.usuario) {
        this.isLoading = true;

        let resultado = await this.esqueciSenha({
          USUARIO: this.usuario
        });

        this.isLoading = false;

        if(resultado.registrado) {
          this.contadorReenviar = 60;
          this.confirmarCodigo = true;
        } else {
          Swal.fire({
            icon: "error", 
            title: "Erro", 
            text: "O e-mail ou celular não está cadastrado.",
            timer: 3000, 
            timerProgressBar: true
          });
        }
      } else {
        Swal.fire({
          icon: "error", 
          title: "Erro", 
          text: "Preencha o e-mail ou senha.",
          timer: 3000, 
          timerProgressBar: true
        });
      }
    },
    iniciarContadorReenviar() {
      this.timerContadorReenviar = setInterval(() => {
        if(this.contadorReenviar > 0) {
          this.contadorReenviar--;
        }
      }, 1000);
    },
    reenviarCodigoRecuperacao() {
      this.enviar();
    },
    async verificarCodigoRecuperacao() {
      this.codigoRecuperacao = this.codigoRecuperacao1 + 
        this.codigoRecuperacao2 + 
        this.codigoRecuperacao3 + 
        this.codigoRecuperacao4 + 
        this.codigoRecuperacao5 + 
        this.codigoRecuperacao6;

      if(this.codigoRecuperacao.length == 6) {
        let checagem = await this.checarCodigoRecuperacao({
          USUARIO: this.usuario, 
          CODIGO_RECUPERACAO: this.codigoRecuperacao
        });

        if(checagem.aceito) {
          this.inserirNovaSenha = true;
        } else {
          Swal.fire({
            icon: "error", 
            title: "Erro", 
            text: "O código de recuperação informado é inválido.",
            timer: 3000, 
            timerProgressBar: true
          });
        }
      }
    },
    focusInput(event, codigo) {
      if(!isNaN(event.key)) {
        if(codigo == 1) {
          this.$refs.codigoRecuperacao1.focus();
        } else if(codigo == 2) {
          this.$refs.codigoRecuperacao2.focus();
        } else if(codigo == 3) {
          this.$refs.codigoRecuperacao3.focus();
        } else if(codigo == 4) {
          this.$refs.codigoRecuperacao4.focus();
        } else if(codigo == 5) {
          this.$refs.codigoRecuperacao5.focus();
        } else if(codigo == 6) {
          this.$refs.codigoRecuperacao6.focus();
        } else if(codigo == 7) {
          this.verificarCodigoRecuperacao();
        }
      } else if(event.key == "Backspace") {
        if(codigo == 3) {
          this.$refs.codigoRecuperacao1.focus();
        } else if(codigo == 4) {
          this.$refs.codigoRecuperacao2.focus();
        } else if(codigo == 5) {
          this.$refs.codigoRecuperacao3.focus();
        } else if(codigo == 6) {
          this.$refs.codigoRecuperacao4.focus();
        } else if(codigo == 7) {
          this.$refs.codigoRecuperacao5.focus();
        }
      }
    },
    async enviarNovaSenha() {
      if(this.senha) {
        if(this.senhaRepeticao) {
          if(this.senha == this.senhaRepeticao) {
            if(this.$refs.qualidadeSenha.obterQualidade() == 4) {
              let checagem = await this.checarCodigoRecuperacao({
                USUARIO: this.usuario, 
                CODIGO_RECUPERACAO: this.codigoRecuperacao, 
                SENHA: this.senha
              });

              if(checagem.aceito) {
                this.$refs.alertBox.show(
                  "success", 
                  "Sucesso", 
                  "", 
                  "", 
                  "Sua senha foi alterada com sucesso!"
                );
              } else {
                Swal.fire({
                  icon: "error", 
                  title: "Erro", 
                  text: "Ocorreu um erro ao tentar definir uma nova senha.",
                  timer: 3000, 
                  timerProgressBar: true
                });
              }
            } else {
              Swal.fire({
                icon: "error", 
                title: "Erro", 
                text: "A senha deve conter no mínimo 8 caracteres, sendo pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial.",
                timer: 3000, 
                timerProgressBar: true
              });
            }
          } else {
            Swal.fire({
              icon: "error", 
              title: "Erro", 
              text: "As senhas informadas não conferem.",
              timer: 3000, 
              timerProgressBar: true
            });
          }
        } else {
          Swal.fire({
            icon: "error", 
            title: "Erro", 
            text: "Preencha a repetição da senha.",
            timer: 3000, 
            timerProgressBar: true
          });
        }
      } else {
        Swal.fire({
          icon: "error", 
          title: "Erro", 
          text: "Preencha a senha.", 
          timer: 3000, 
          timerProgressBar: true
        });
      }
    },
    dismissAlert() {
      this.voltar();
    },
    voltar() {
      this.$router.push({
        name: "login"
      });
    }
  }
}
</script>

<style>

</style>