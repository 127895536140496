<template>
  <div class="tf-panel down" :class="{'panel-open': visible}">
    <div class="panel_overlay" @click="close"></div>
    <div class="panel-box panel-down">
      <div class="header bg_white_color">
        <div class="tf-container">
          <div class="tf-statusbar d-flex justify-content-center align-items-center">
            <a href="javascript:;" class="clear-panel" @click="close">
              <i class="icon-close1"></i>
            </a>
            <h3>{{ title }}</h3>
          </div>
        </div>
      </div>
      <div class="wrap-transfer mb-5">
        <div class="tf-container">
          <a href="javascript:;" class="action-sheet-transfer" v-for="opcao in opcoes" :key="opcao.value" @click="$emit('selecionado', opcao)">
            <div class="icon"><i class="icon-friends"></i></div>
            <div class="content">
              <h4 class="fw_6">
                {{ opcao.title }}
              </h4>
              <p>{{ opcao.description }}</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PanelTopDown", 
  data() {
    return {
      opcoes: [],
      visible: false
    };
  },
  props: ["title"], 
  methods: {
    exibirOpcoes(opcoes) {
      this.visible = true;
      this.opcoes = opcoes;
    },
    close() {
      this.visible = false;
    }
  }
}
</script>

<style>

</style>